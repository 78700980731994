import React from "react";
import {
  Col
} from 'react-bootstrap';
import axios from "axios";
import sendMessage from "../SendMessage";
import parse from 'html-react-parser';


class CustomText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backend_url: process.env.REACT_APP_BACKEND_URL,
      shop: null,
      content: '',
    };
  }

  getShop = async () => {
    function getQueryStringParams(query) {
      return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
    };
    let search = getQueryStringParams(window.location.search);
    if(search.shop) {
      this.setState({ shop: search.shop });
      return true;
    }
  }

  getCustomText = () => {
     axios.post(this.state.backend_url+'/widgets/get-custom-text', {shop: this.state.shop})
      .then((response) => {
        this.setState({
          content: response.data.custom_text
        }, () => {
          sendMessage('widgetDone');
        });
     });
  };

  componentDidMount() {
    this.getShop().then(async() => {
      this.getCustomText();
    });
  }

  render() {

    return (
      <Col className="box-border custom-text" data-iframe-height="iFrameResizer">
        {parse(this.state.content)}
      </Col>
    );
  }
}

export default CustomText;