import React from "react";
import {
  Col
} from 'react-bootstrap';
import axios from "axios";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import sendMessage from '../SendMessage';
import { Translation } from 'react-i18next';
import i18next from "i18next";

class DiscountNextOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backend_url: process.env.REACT_APP_BACKEND_URL,
      shop: null,
      header: '',
      description: '',
      code: '',
      content: ''
    };
  }

  getShop = async () => {
    function getQueryStringParams(query) {
      return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params, param) => {
            let [key, value] = param.split('=');
            params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
            return params;
          }, {})
      : {}
    };
    let search = getQueryStringParams(window.location.search);
    if(search.shop) {
      this.setState({ shop: search.shop });
      return true;
    }
  }

  getDiscountNextOrder = () => {
     axios.post(this.state.backend_url+'/widgets/get-discount-next-order', {shop: this.state.shop})
      .then((response) => {
        this.setState({
          header: response.data.widget.header,
          description: response.data.widget.description,
          code: response.data.widget.code,
          content: response.data.widget.code,
          lang: response.data.lang
        }, () => {
          if(this.state.lang !== 'en') {
            i18next.changeLanguage(this.state.lang, (err, t) => {
              if (err) return console.log('something went wrong loading', err);
            });
          }
          sendMessage('widgetDone');
        });
     });
  };

  componentDidMount() {
    this.getShop().then(async() => {
      this.getDiscountNextOrder();
    });
  }

  render() {

    return (
      <Col className="box-border" data-iframe-height="iFrameResizer">
        <h4>{this.state.header}</h4>
        <p>{this.state.description}</p>
        <Translation>
          {(t, { i18n }) => 
            <CopyToClipboard 
              text={this.state.code}
              onCopy={() => this.setState({content: t('Copied')})}
            >
              <span className="discount-code"
                onMouseEnter={() => this.setState({content: t('Click to copy')})}
                onMouseLeave={() => this.setState({content: this.state.code})}
              >
                {this.state.content}
              </span>
            </CopyToClipboard>
          }
        </Translation>
      </Col>
    );
  }
}

export default DiscountNextOrder;