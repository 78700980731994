import React, {Fragment} from "react";
import {
  Col,
  Row,
  Form
} from 'react-bootstrap';
import axios from "axios";
import sendMessage from '../SendMessage';
import moment from 'moment';
import { Trans, Translation } from 'react-i18next';
import i18next from "i18next";

class BirthdayCollector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backend_url: process.env.REACT_APP_BACKEND_URL,
      shop: null,
      header: '',
      subheader: '',
      day: null,
      month: null,
      year: null,
      birthday: '',
      loadCheckout: false,
      selectedDate: moment().format('YYYY')+'-01-01',
      thanks: false,
      message: '',
      message_future: '',
      message_past: '',
      years: [],
      days: []
    };
  }

  getShop = async () => {
    function getQueryStringParams(query) {
      return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params, param) => {
            let [key, value] = param.split('=');
            params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
            return params;
          }, {}
          )
        : {}
    };
    let search = getQueryStringParams(window.location.search);
    if(search.shop) {
      this.setState({
        shop: search.shop,
        currency: search.currency,
        name: search.name,
        email: search.email
      });
      return true;
    }
  }

  getBirthdayCollector = () => {
     axios.post(this.state.backend_url+'/widgets/get-birthday-collector', {shop: this.state.shop})
      .then((response) => {
        this.setState({
          header: response.data.widget.header,
          subheader: response.data.widget.subheader,
          message: response.data.widget.message,
          message_past: response.data.widget.message_past,
          message_future: response.data.widget.message_future,
          lang: response.data.lang
        }, () => {
          if(this.state.lang !== 'en') {
            i18next.changeLanguage(this.state.lang, (err, t) => {
              if (err) return console.log('something went wrong loading', err);
            });
          }
          sendMessage('widgetDone');
        });
     });
  };

  sendForm = () => {
    this.setState({
      selectedDate: `${this.state.year}-${this.state.month}-${this.state.day}`
    }, () => {
      this.setState({loadCheckout: true});
      axios.post(this.state.backend_url+'/widgets/add-birthday', {
          shop: this.state.shop,
          name: this.state.name,
          email: this.state.email,
          birthday: this.state.selectedDate
        })
        .then((response) => {
          this.setState({
            loadCheckout: false,
            thanks: true
          });
      });
    });
  };

  countDaysToBirthday = () => {
    let next_birthday, response, days;
    let birthday_month_day = moment(this.state.selectedDate).format('MM-DD');
    let this_year = moment().format('YYYY');
    let next_year = moment().add(1, 'years').format('YYYY');
    let age = this_year-moment(this.state.selectedDate).format('YYYY');
    if(moment(this.state.selectedDate).format('MM-DD') === moment().format('MM-DD')) {
      //Birthday today
      next_birthday = moment(this.state.selectedDate).add(1, 'years').format('YYYY-MM-DD');
      days = moment(next_birthday).endOf('day').fromNow();
      response = this.state.message;
      response = response.replace("{age}", age+'th');
      response = response.replace("{name}", this.state.name);
    } else if(moment(this.state.selectedDate).format('MM-DD') > moment().format('MM-DD')) {
      //Future date
      next_birthday = moment(this_year+'-'+birthday_month_day).format('YYYY-MM-DD');
      days = moment(next_birthday).endOf('day').fromNow();
      response = this.state.message_future;
      response = response.replace("{name}", this.state.name);
      response = response.replace("{in_time}", days);
    } else {
      //Old date
      next_birthday = moment(next_year+'-'+birthday_month_day).format('YYYY-MM-DD');
      days = moment(next_birthday).endOf('day').fromNow();
      response = this.state.message_past;
      response = response.replace("{name}", this.state.name);
      response = response.replace("{in_time}", days);
    }
    return response;
  };

  createYears = () => {
    let years = [];
    for(let i=1900; i<=moment().format('YYYY'); i++) {
      years.push({year: i});
    }
    years.reverse();
    this.setState({years: years});
  };

  createDays = (number) => {
    let days = [];
    for(let i=1; i<=number; i++) {
      if(i < 10) {
        days.push({day: `0${i}`});
      } else {
        days.push({day: i});
      }
    }
    this.setState({days: days});
  };

  handleChange = (type, val) => {
    switch(type){
      case 'day':
        this.setState({ day: val });
        break;
      case 'month':
        if(val === '02') {
          this.createDays(29);
        } else if(val === '04' || val === '06' || val === '09' || val === '11') {
          this.createDays(30);
        } else {
          this.createDays(31);
        }
        this.setState({ month: val });
        break;
      case 'year':
        this.setState({ year: val });
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    this.createYears();
    this.createDays(31);
    this.getShop().then(async() => {
      window.addEventListener("message", this.receiveMessage, false);
      this.getBirthdayCollector();
    });
  }

  render() {

    return (
      <Col className="box-border" data-iframe-height="iFrameResizer">
        <Row className="birthdaycollector">
          <Col>
            <h4>{this.state.header}</h4>
            {this.state.thanks ? (
              <p>{this.countDaysToBirthday()}</p>
            ):(
              <Fragment>
                <p>{this.state.subheader}</p>
                <Form.Control name="year" value={this.state.year} onChange={(event) => this.handleChange('year', event.target.value)} as="select">
                  <Translation>
                    {(t, { i18n }) => 
                      <option value="null">
                        {t('Year')}
                      </option>
                    }
                  </Translation>
                  { this.state.years.map((data, index) => (
                    <option key={index} value={data.year}>
                      {data.year}
                    </option>
                  )) }
                </Form.Control>
                <Form.Control name="month" value={this.state.month} onChange={(event) => this.handleChange('month', event.target.value)} as="select">
                  <Translation>
                    {(t, { i18n }) => 
                      <Fragment>
                        <option value="null">
                          {t('Month')}
                        </option>
                        <option value="01">
                          {t('January')}
                        </option>
                        <option value="02">
                          {t('February')}
                        </option>
                        <option value="03">
                          {t('March')}
                        </option>
                        <option value="04">
                          {t('April')}
                        </option>
                        <option value="05">
                          {t('May')}
                        </option>
                        <option value="06">
                          {t('June')}
                        </option>
                        <option value="07">
                          {t('July')}
                        </option>
                        <option value="08">
                          {t('August')}
                        </option>
                        <option value="09">
                          {t('September')}
                        </option>
                        <option value="10">
                          {t('October')}
                        </option>
                        <option value="11">
                          {t('November')}
                        </option>
                        <option value="12">
                          {t('December')}
                        </option>
                      </Fragment>
                    }
                  </Translation>
                </Form.Control>
                <Form.Control name="day" value={this.state.day} onChange={(event) => this.handleChange('day', event.target.value)} as="select">
                  <Translation>
                    {(t, { i18n }) => 
                      <option value="null">
                        {t('Day')}
                      </option>
                    }
                  </Translation>
                  { this.state.days.map((data, index) => (
                    <option key={index} value={data.day}>
                      {data.day}
                    </option>
                  )) }
                </Form.Control>
                
                {this.state.loadCheckout ? (
                  <span className="hypecart-button disabled">
                    <Trans i18nKey="Loading" />
                  </span>
                ) : (
                  <Translation>
                    {(t, { i18n }) => 
                      <span className="hypecart-button" onClick={() => this.sendForm()}>
                        {t('Submit')}
                      </span>
                    }
                  </Translation>
                )}
              </Fragment>
            )}
            
          </Col>
        </Row>
      </Col>
    );
  }
}

export default BirthdayCollector;