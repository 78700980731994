import React from "react";
import {
  Col,
  Row
} from 'react-bootstrap';
import Skeleton from "react-loading-skeleton";

class CartItemSkeleton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <Col xs={12} className="cart-product-loading">
        <Row className="d-flex align-items-center justify-content-center">
          <Col xs={3}>
            <Skeleton width={90} height={80} />
          </Col>
          <Col xs={9} sm={6} className="meta">
            <Skeleton count={2} />
          </Col>
          <Col xs={12} sm={3} className="price">
            <Skeleton count={1} />
          </Col>
        </Row>
      </Col>
    );
  }
}

export default CartItemSkeleton;

