import React, { Fragment } from "react";
import {
  Row,
  Col,
} from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';

class CartHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <Row className="cart-header">
        <Col xs={6} sm={8}>
          {this.props.show_cart &&
            <h1><Trans i18nKey="Your cart" /></h1>
          }
          {this.props.show_upsell_product &&
            <h1><Trans i18nKey="Add item" /></h1>
          }
          {this.props.show_crossSell_product &&
            <h1><Trans i18nKey="Add item" /></h1>
          }
          {this.props.show_product &&
            <h1><Trans i18nKey="Edit item" /></h1>
          }
        </Col>
        <Col xs={5} sm={3}>
          {this.props.show_cart && (
            <h4>
              {this.props.cart_items_number} <Trans i18nKey="items" />
            </h4>
          )}
        </Col>
        <Col xs={1}>
          {this.props.show_cart &&
            <span className="close" onClick={() => this.props.closeCart()}>
              <AiOutlineClose />
            </span>
          }
          {(() => {
            if (this.props.show_upsell_product) {
              return (
                <span className="close" onClick={() => this.props.closeProduct()}>
                  <AiOutlineClose />
                </span>
              )
            } else if (this.props.show_crossSell_product) {
              return (
                <span className="close" onClick={() => this.props.closeProduct()}>
                  <AiOutlineClose />
                </span>
              )
            } else if (this.props.show_product) {
              return (
                <span className="close" onClick={() => this.props.closeProduct()}>
                  <AiOutlineClose />
                </span>
              )
            } else {
              return (
                <Fragment></Fragment>
              )
            }
          })()}
        </Col>
      </Row>
    );
  }
}

export default CartHeader;

