import React from "react";
import {
  Col
} from 'react-bootstrap';
import axios from "axios";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import sendMessage from '../SendMessage';

class YoutubeVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backend_url: process.env.REACT_APP_BACKEND_URL,
      shop: null,
      header: '',
      url: ''
    };
  }

  getShop = async () => {
    function getQueryStringParams(query) {
      return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
    };
    let search = getQueryStringParams(window.location.search);
    if(search.shop) {
      this.setState({ shop: search.shop });
      return true;
    }
  }

  getYoutubeVideo = () => {
     axios.post(this.state.backend_url+'/widgets/get-youtube-video', {shop: this.state.shop})
      .then((response) => {
        this.setState({
          header: response.data.header,
          url: response.data.url
        }, () => {
          /*let windowHeight = window.document.documentElement.scrollHeight;
          sendMessage('getHeight', {widget: 'youtube-video', height: windowHeight});*/
          sendMessage('widgetDone');
        });
     });
  };

  componentDidMount() {
    this.getShop().then(async() => {
      this.getYoutubeVideo();
    });
  }

  render() {

    return (
      <Col className="box-border" data-iframe-height="iFrameResizer">
        <h4>{this.state.header}</h4>
        <LiteYouTubeEmbed id={this.state.url} />
      </Col>
    );
  }
}

export default YoutubeVideo;