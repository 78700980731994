import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import HypeCart from './HypeCart';
import SocialMedia from './components/widgets/SocialMedia';
import DiscountNextOrder from './components/widgets/DiscountNextOrder';
import YoutubeVideo from './components/widgets/YoutubeVideo';
import ProductRecommendations from './components/widgets/ProductRecommendations';
import ReOrder from './components/widgets/ReOrder';
import ProductUpsell from './components/widgets/ProductUpsell';
import BirthdayCollector from './components/widgets/BirthdayCollector';
import CustomText from './components/widgets/CustomText';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/widgets/social-media">
          <SocialMedia />
        </Route>
        <Route path="/widgets/discount-next-order">
          <DiscountNextOrder />
        </Route>
        <Route path="/widgets/youtube-video">
          <YoutubeVideo />
        </Route>
        <Route path="/widgets/product-recommendations">
          <ProductRecommendations />
        </Route>
        <Route path="/widgets/reorder">
          <ReOrder />
        </Route>
        <Route path="/widgets/product-upsell">
          <ProductUpsell />
        </Route>
        <Route path="/widgets/birthday-collector">
          <BirthdayCollector />
        </Route>
        <Route path="/widgets/custom-text">
          <CustomText />
        </Route>
        <Route path="/">
          <HypeCart />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
