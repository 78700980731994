import React from 'react';
import {
  Col,
  Row,
  ProgressBar
} from 'react-bootstrap';
import formatCurrency from '../Helpers';

class Progressbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_progressbar: true,
      progress: 0,
    };
  }

  text = () => {
    this.setState({
      loading_progressbar: false
     });
     this.props.handleState('loaded_triggers', 1);
  }


  componentDidMount() {
    this.text();
  }

  render() {

    const subtitle = () => {
      let return_value;
      
      switch(this.props.type) {
        case 'cart_value':
          if(this.props.total_cart_value/100 < this.props.threshold) {
            return_value = this.props.subtitle.replace('{amount}', formatCurrency(this.props.left_to_spend, this.props.currency));
          } else {
            return_value = this.props.completion;
          }
        break;
        case 'cart_quantity':
          if(this.props.total_cart_quantity < this.props.threshold) {
            return_value = this.props.subtitle.replace('{amount}', Number(this.props.threshold - this.props.total_cart_quantity));
          } else {
            return_value = this.props.completion;
          }
        break;
        default:
          if(this.props.total_cart_value/100 < this.props.threshold) {
            return_value = this.props.subtitle.replace('{amount}', formatCurrency(this.props.left_to_spend, this.props.currency));
          } else {
            return_value = this.props.completion;
          }
        break;
      }
            
      return return_value;
      
    }

    const image = () => {
      let return_value;
      if(this.props.image) {
        if(this.props.theme === "cart-dark"){
          switch (this.props.image) {
            case 'https://hypecart.fra1.digitaloceanspaces.com/assets/trigger-icons/icon1.png':
              return_value = "https://hypecart.fra1.digitaloceanspaces.com/assets/trigger-icons/icon1_dark.png"
              break;
            case 'https://hypecart.fra1.digitaloceanspaces.com/assets/trigger-icons/icon2.png':
              return_value = "https://hypecart.fra1.digitaloceanspaces.com/assets/trigger-icons/icon2_dark.png"
              break;
            case 'https://hypecart.fra1.digitaloceanspaces.com/assets/trigger-icons/icon3.png':
              return_value = "https://hypecart.fra1.digitaloceanspaces.com/assets/trigger-icons/icon3_dark.png"
              break;
            case 'https://hypecart.fra1.digitaloceanspaces.com/assets/trigger-icons/icon4.png':
              return_value = "https://hypecart.fra1.digitaloceanspaces.com/assets/trigger-icons/icon4_dark.png"
              break;
            default:
              return_value = this.props.image;
              break;
          }
        } else {
          return_value = this.props.image
        }
      } else {
        return_value = "https://www.bcwsupplies.com/pub/media/images/free_shipping/Free_Shipping_RTL_2020.png";
      }
      return return_value;
    }

    let background_type;
    if(this.props.product_image_contain) {
      background_type = 'contain';
    } else {
      background_type = 'cover';
    }
    
    return (
      <Row className="cart-product no-hover">
        <Col xs={3} className="cart-img-outer">
          <div className="cart-img" 
            style={{  
              backgroundImage: "url(" + image() + ")",
              backgroundPosition: 'center',
              backgroundSize: background_type,
              backgroundRepeat: 'no-repeat'
            }}></div>
        </Col>
        <Col xs={9} className="meta">
          <span>
            <h3>{this.props.title}</h3>
            <h4>{subtitle()}</h4>
            <ProgressBar variant="success" animated now={this.props.progressbar} />
          </span>
        </Col>
      </Row>
    );
  }
}

export default Progressbar;

