import React from "react";
import {
  Col,
  Row
} from 'react-bootstrap';
import axios from "axios";
import sendMessage from '../SendMessage';
import i18next from "i18next";
import { Trans } from 'react-i18next';
import formatCurrency from '../../Helpers';

class ProductRecommendations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backend_url: process.env.REACT_APP_BACKEND_URL,
      shop: null,
      header: '',
      products: [],
      loadCheckout: false
    };
  }

  getShop = async () => {
    function getQueryStringParams(query) {
      return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
    };
    let search = getQueryStringParams(window.location.search);
    if(search.shop) {
      this.setState({ shop: search.shop });
      return true;
    }
  }

  getProductRecommendations = () => {
     axios.post(this.state.backend_url+'/widgets/get-product-recommendations', {shop: this.state.shop})
      .then((response) => {
        this.setState({
          header: response.data.widget.header,
          lang: response.data.lang
        }, () => {
          if(this.state.lang !== 'en') {
            i18next.changeLanguage(this.state.lang, (err, t) => {
              if (err) return console.log('something went wrong loading', err);
            });
          }
          sendMessage('getProducts', {widget: 'product-recommendations'});
        });
     });
  };

  addConversion = () => {
    axios.post(this.state.backend_url+'/conversions/create', {
      shop: this.state.shop,
      cart_token: this.state.cart_token,
      conversion_id: this.state.conversion_id,
      product_id: this.state.product_id
    }).then(function(res) {
      console.log("Conversion added from product recommendations")
    })
    .catch(function(err){
      console.log(err)
    })
  }

  getProductURL = (id) => {
    
    this.setState({loadCheckout: true});
    let line_items = [{
      variant_id: id,
      quantity: 1
    }];
    axios.post(this.state.backend_url+'/widgets/get-product-upsell-url', {
      shop: this.state.shop,
      line_items: line_items,
    })
    .then((response) => {
      console.log(response.data)
      let checkout_url = response.data.checkout_url;
      this.setState({
        cart_token: response.data.cart_token,
        conversion_id: "product recommendations widget",
        product_id: response.data.product_id
      }, () => {
        window.open(checkout_url);
        this.addConversion();
        this.setState({loadCheckout: false});
      })
    });
  }

  receiveMessage = (event) => {
    const message = event.data.message;
    switch (message) {
      case 'randomProduct':
        this.setState({
          products: event.data.value.data.products,
          currency: event.data.value.currency
        }, () => {
          sendMessage('widgetDone');
        });
        break;
      default:
        break;
    }
  }

  limit = (str) => {
    if(str.length > 25) str = str.substring(0,25)+"...";
    return str;
  };

  componentDidMount() {
    this.getShop().then(async() => {
      window.addEventListener("message", this.receiveMessage, false);
      this.getProductRecommendations();
    });
  }

  render() {
    return (
      <Col className="box-border" data-iframe-height="iFrameResizer">
        <Row className="recommended">
          <h4>{this.state.header}</h4>
        {this.state.products.map((item, index) => (
            <Col key={index} xs={4} className="item">
              <div className="image" style={{  
                backgroundImage: "url(" + item.featured_image + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              }}
            ></div>
              <span className="product-name">{this.limit(item.title)}</span>
              <div className="bottom">
                <span className="price">
                  {formatCurrency(item.price/100, this.state.currency)}
                </span>
                {this.state.loadCheckout ? (
                  <span className="hypecart-button disabled">
                    <Trans i18nKey="Loading" />
                  </span>
                ) : (
                  <a target="_blank" onClick={() => this.getProductURL(item.variants[0].id)}>
                    <span className="hypecart-button"><Trans i18nKey="Buy now" /></span>
                  </a>
                )
                }
                
              </div>
            </Col>
          )
        )}
        </Row>
      </Col>
    );
  }
}

export default ProductRecommendations;