import React from "react";
import {
  Col,
} from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { RiShoppingCartLine } from 'react-icons/ri';

class EmptyCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <Col className="empty-cart">
        <RiShoppingCartLine />
        <p><Trans i18nKey="Your cart is empty" /></p>
      </Col>
    );
  }
}

export default EmptyCart;

