import React, { Fragment } from "react";
import {
  Col,
  Row,
  Button,
  Spinner,
  Form
} from 'react-bootstrap';
import { Trans, Translation } from 'react-i18next';
import sendMessage from './SendMessage';
import formatCurrency from '../Helpers';
import axios from 'axios'

class CartFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_checkout: false
    };
  }

  goToCheckout = () => {

   if(this.props.shop === 'hypecartdemo.myshopify.com') {
      window.parent.location.href = 'https://demo.hypecart.co/58160545947/orders/1edb4a45298c06df7199783cf33cde7d';
    } else {
      if(this.props.show_note) {
        sendMessage('addNoteAndGoToCheckout', this.props.note);
      } else {
        sendMessage('goToCheckout');
      }
      this.setState({checkoutLoading:true});
    }
 
/*
    let attributes = sessionStorage.getItem('attributes')
    console.log(attributes)

    if(this.props.shop === 'hypecartdemo.myshopify.com') {
      window.parent.location.href = 'https://demo.hypecart.co/58160545947/orders/1edb4a45298c06df7199783cf33cde7d';
    } else {
      this.setState({loading_checkout: true})

      axios.post(process.env.REACT_APP_BACKEND_URL+'/cart/checkout', {
        shop: this.props.shop,
        line_items: this.props.cart_items,
        note: this.props.note,
        attributes: attributes
      }).then((res) => {
        if(res.status === 200) {
          window.parent.location.href = res.data
        }
      }).catch((err) => {
        console.log(err)
      })      
    }*/
  };

  removeFromCart = () => {
    sendMessage('updateQuantity', {
      'id': this.props.cart_items[this.props.product_index].variant_id,
      'quantity': 0
    });
    this.props.closeProduct()
  }

  componentDidMount() {
  }

  render() {
    
    return (
      <Row className="cart-footer">
        {this.props.show_cart &&
          <Fragment>
            {this.props.show_terms &&
              <Fragment>
                <Col xs={12}>
                  {this.props.terms_heading !== '' &&
                    <Fragment>
                      <Form.Check
                        inline
                        type="checkbox"
                        id="terms"
                      >
                        <Form.Check.Input type="checkbox" onChange={(val) => this.setState({checked: val.target.checked})}/>
                        <Form.Check.Label>
                          <span className="terms">
                            <a href={this.props.terms_url} target="_blank" rel="noreferrer">{this.props.terms_heading}</a>
                          </span>
                        </Form.Check.Label>
                      
                      </Form.Check>
                    </Fragment>
                  }
                </Col>
              </Fragment>
            }
            {this.props.show_note &&
              <Fragment>
                <Col xs={12}>
                  {this.props.note_heading !== '' &&
                    <span>{this.props.note_heading}</span>
                  }
                  <Translation>
                    {(t, { i18n }) => 
                      <Form.Control 
                        as="textarea" 
                        placeholder={t('Note')} 
                        onChange={(val) => this.props.updateNote(val)} 
                        rows={2} 
                        value={this.props.note ? this.props.note : undefined }
                      />
                    }
                  </Translation>
                </Col>
              </Fragment>
            }
            {this.props.cartIsLoaded &&
              <Fragment>
                {this.props.discounts.length > 0 &&
                  <Fragment>
                    {this.props.discounts.map((item, index) => 
                      <Fragment key={index}>
                        <Col xs={6}>
                          {item.title}
                        </Col>
                        <Col xs={6} className="total">
                        -{formatCurrency(Number(item.value), this.props.currency)}
                        </Col>
                      </Fragment>
                    )}
                  </Fragment>
                }
                <Col xs={6}>
                  <Trans i18nKey="Subtotal" />:
                </Col>
                <Col xs={6} className="total">
                {formatCurrency(Number(this.props.total_cart_value / 100) - this.props.total_discount, this.props.currency)}
                </Col>
              </Fragment>
            }
            <Col xs={12} className="checkout">
              {this.props.cartIsLoaded ? (
                <Fragment>
                  {this.props.cartIsEmpty ? (
                    <Button size="lg" block disabled>
                      <Trans i18nKey="Checkout" />
                    </Button>
                  ):(
                    <Fragment>
                      {this.props.checkoutLoading ? (
                        <Button size="lg" block disabled>
                          <Spinner size="sm" animation="border" /> &nbsp;<Trans i18nKey="Preparing checkout" />...
                        </Button>
                      ):(
                        <Fragment>
                          {!this.state.checked && this.props.show_terms ? (
                            <Button size="lg" block disabled>
                              <Trans i18nKey="Checkout" />
                            </Button>
                          ) : (
                            <Fragment>
                              {this.state.loading_checkout ? (
                                <Button size="lg" block disabled>
                                  <Spinner size="sm" animation="border" /> &nbsp;<Trans i18nKey="Preparing checkout" />...
                                </Button>
                              ):(
                                <Button size="lg" block onClick={() => { this.goToCheckout(); }}>
                                  <Trans i18nKey="Checkout" />
                                </Button>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              ):(
                <Button size="lg" block disabled>
                  <Trans i18nKey="Checkout" />
                </Button>
              )}
            </Col>
          </Fragment>
        }
        {this.props.show_product &&
          <Fragment>
            <Col xs={12} className="save-cart">
              {this.props.showSave ? (
                <Button size="lg" block onClick={() => this.props.updateCartItem()}>
                  <Trans i18nKey="Save" />
                </Button>
              ):(
                <Button size="lg" variant="outline-secondary" block onClick={() => this.removeFromCart()}>
                  <Trans i18nKey="Remove from cart" />
                </Button>
              )}
            </Col>
          </Fragment>
        }
        {this.props.show_upsell_product &&
          <Fragment>
            <Col xs={12} className="save-cart">
              <Button size="lg" block onClick={() => this.props.addProduct(this.props.upsell_product_id, this.props.upsell_product_quantity)}>
                <Trans i18nKey="Add to cart" />
              </Button>
            </Col>
          </Fragment>
        }
        {this.props.show_crossSell_product &&
          <Fragment>
            <Col xs={12} className="save-cart">
              <Button size="lg" block onClick={() => this.props.addProduct(this.props.crossSell_product_id, this.props.crossSell_product_quantity)}>
                <Trans i18nKey="Add to cart" />
              </Button>
            </Col>
          </Fragment>
        }
        {!this.props.show_cart &&
        <Col xs={12} className="back-to-cart">
          <Button size="lg" block variant="outline-secondary" onClick={() => this.props.closeProduct()}>
            <Trans i18nKey="Back to cart" />
          </Button>
        </Col>
        }
        {this.props.plan === "free" && 
          <span className="poweredby">
            <a href="https://hypecart.co" target="_blank" rel="noreferrer">
              ⚡️Powered by HypeCart
            </a>
          </span>
        }
      </Row>
    );
  }
}

export default CartFooter;

