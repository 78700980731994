import React, { Fragment } from "react";
import {
  Col,
  Row,
  Button,
  InputGroup,
  FormControl,
  Form,
} from 'react-bootstrap';
import { Trans } from 'react-i18next';
import _ from 'lodash';
import axios from "axios";
import formatCurrency from '../Helpers';

class CartUpsellProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      variant: this.props.triggers.upsellProduct[this.props.upsell_product_index].item.variants[0],
      variantID: this.props.triggers.upsellProduct[this.props.upsell_product_index].item.variants[0].id,
      backend_url: process.env.REACT_APP_BACKEND_URL,
      inventory: null,
      price: 0,
      image: ''
    };
  }

  onVariantChange = (event) => {
    let newVariant = event.target.value;
    this.setState({ variant: newVariant });
    let newVariantObject = _.find(this.props.triggers.upsellProduct[this.props.upsell_product_index].item.variants, ['id', parseInt(newVariant)]);
    
    let image;
    if(newVariantObject.featured_image === null) {
      image = this.state.backup_image;
    } else {
      image = newVariantObject.featured_image.src;
    }
    
    this.setState({
      price: newVariantObject.price,
      image: image
    })

    let limit = _.find(this.state.inventory, ['id', parseInt(newVariant)]);

    if(limit.inventory_management === null) {
      limit = 9999999999;
    } else {
      limit = limit.inventory_quantity;
    }

    let quantity = this.state.quantity;
    if(quantity > limit) {
      this.setState({ quantity: limit });
    }
    
    this.setState({variant: newVariantObject, variantID: newVariant }, () => {
      this.props.handleAddProduct(this.state.variantID, this.state.quantity)
    })
  }

  changeQuantity = (type) => {
    let limit = _.find(this.state.inventory, ['id', parseInt(this.state.variantID)]);
    limit = limit.inventory_quantity;
    
    if(type === "add") {
      if(this.state.quantity !== limit) {
        this.setState({quantity: this.state.quantity+1}, () => {
          this.props.handleAddProduct(this.state.variantID, this.state.quantity)
        })
      }
    } else if(type === "remove") {
      if(this.state.quantity !== 0) {
        this.setState({quantity: this.state.quantity-1}, () => {
          this.props.handleAddProduct(this.state.variantID, this.state.quantity)
        })
      }
    } else {
      if(Math.sign(type) === 1) {
        this.setState({quantity: type}, () => {
          this.props.handleAddProduct(this.state.variantID, this.state.quantity)
        })
      } else {
        this.setState({quantity: 0}, () => {
          this.props.handleAddProduct(this.state.variantID, this.state.quantity)
        })
      }
    }
  }

  getInventory = async () => {
    if(this.props.triggers.upsellProduct[this.props.upsell_product_index].item) {
      axios.post(this.state.backend_url+'/cart/inventory', {
        shop: this.props.shop,
        product_id: this.props.triggers.upsellProduct[this.props.upsell_product_index].item.id
      }).then((response) => {
        this.setState({ inventory: response.data })
        return true;
      }).catch((err) => {
        console.log(err)
      });

    }
  }

  componentDidMount = async ()  => {
    this.props.handleAddProduct(this.state.variantID, this.state.quantity);
    await this.getInventory();
    let image;
    if(this.props.triggers.upsellProduct[this.props.upsell_product_index].item.variants[0].featured_image === null) {
      image = this.props.triggers.upsellProduct[this.props.upsell_product_index].item.featured_image
    } else {
      image = this.props.triggers.upsellProduct[this.props.upsell_product_index].item.variants[0].featured_image.src
    }
    this.setState({
      price: this.props.triggers.upsellProduct[this.props.upsell_product_index].item.variants[0].price,
      image: image,
      backup_image: this.props.triggers.upsellProduct[this.props.upsell_product_index].item.featured_image
    })

  }

  render() {

    let background_type;
    if(this.props.product_image_contain) {
      background_type = 'contain';
    } else {
      background_type = 'cover';
    }

    return (
      <Fragment>
        <Row className="cart-product">
          <Col xs={3} className="cart-img-outer">
            <div className="cart-img" 
              style={{  
                backgroundImage: "url(" + this.state.image + ")",
                backgroundPosition: 'center',
                backgroundSize: background_type,
                backgroundRepeat: 'no-repeat'
              }}></div>
          </Col>
          <Col xs={6} className="meta">
            <span>
              <h3>
                {this.props.triggers.upsellProduct[this.props.upsell_product_index].item.title}
              </h3>
              <h4>
                {this.props.triggers.upsellProduct[this.props.upsell_product_index].item.options.map((option, index) => {
                  return (
                    <Fragment key={index}>
                      {option.name}: {this.state.variant.options[index]}&nbsp;
                    </Fragment>
                  )  
                })}
              </h4>
              <h4>
                <Trans i18nKey="Quantity" />: {this.state.quantity}
              </h4>
            </span>
          </Col>
          <Col xs={3} className="price">
            <span>
            {formatCurrency(Number((this.state.price * this.state.quantity) / 100), this.props.currency)}
            </span>
          </Col>
        </Row>
        <Row className="cart-edit">
          <Col xs={6}>
            <h3><Trans i18nKey="Quantity" /></h3>
            <InputGroup>
              <InputGroup.Prepend>
                <Button onClick={() => this.changeQuantity("remove")} variant="outline-secondary" className="remove">-</Button>
              </InputGroup.Prepend>
              <FormControl className="quantity" onChange={(e) => this.changeQuantity(e.target.value)} value={this.state.quantity} />
              <InputGroup.Append>
                <Button onClick={() => this.changeQuantity("add")} variant="outline-secondary" className="add">+</Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col xs={6}>
            {this.props.triggers.upsellProduct[this.props.upsell_product_index].item.variants[0].title !== "Default Title" &&
              <Fragment>
                <h3><Trans i18nKey="Variant" /></h3>
                <Form.Control as="select" onChange={(event) => this.onVariantChange(event)} value={this.state.variantID}>
                  {
                    this.props.triggers.upsellProduct[this.props.upsell_product_index].item.variants.map((option, index) => {
                      return (
                        <option key={index} value={option.id}>{option.title}</option>
                      )
                    })
                  }
                </Form.Control>
              </Fragment>
            }
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default CartUpsellProduct;

