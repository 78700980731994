import React from "react";
import {
  Col,
  Row
} from 'react-bootstrap';
import axios from "axios";
import Countdown from 'react-countdown';
import sendMessage from '../SendMessage';
import i18next from "i18next";
import { Trans } from 'react-i18next';

class ReOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backend_url: process.env.REACT_APP_BACKEND_URL,
      shop: null,
      header: '',
      products: [],
      showBtn: true,
      line_items: [],
      reorder_url: null,
      timer_time: 0,
      key: 0
    };
  }

  getShop = async () => {
    function getQueryStringParams(query) {
      return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
    };
    let search = getQueryStringParams(window.location.search);
    if(search.shop) {
      this.setState({ shop: search.shop });
      return true;
    }
  }

  getReOrder = () => {
     axios.post(this.state.backend_url+'/widgets/get-reorder', {shop: this.state.shop})
      .then((response) => {
        let timerTime = 60*response.data.widget.timer_time*1000;
        this.setState({
          header: response.data.widget.header,
          button: response.data.widget.button,
          timer: response.data.widget.timer,
          timer_time: timerTime,
          discount_percentage: response.data.widget.discount_percentage,
          key: 1,
          lang: response.data.lang
        },() => {
          if(this.state.lang !== 'en') {
            i18next.changeLanguage(this.state.lang, (err, t) => {
              if (err) return console.log('something went wrong loading', err);
            });
          }
          sendMessage('getLineItems');
        });
     });
  };

  getReOrderURL = () => {
    axios.post(this.state.backend_url+'/widgets/get-reorder-url', {
      shop: this.state.shop,
      line_items: this.state.line_items,
      discount: this.state.discount_percentage
    })
    .then((response) => {
      this.setState({
        reorder_url: response.data.reorder_url,
        cart_token: response.data.cart_token,
        conversion_id: "reorder widget",
        product_id: response.data.product_id
      }, () => {
        sendMessage('widgetDone');
      });
    });
  };

  addConversion = () => {
    axios.post(this.state.backend_url+'/conversions/create', {
      shop: this.state.shop,
      cart_token: this.state.cart_token,
      conversion_id: this.state.conversion_id,
      product_id: this.state.product_id
    }).then(function(res) {
      console.log("Conversion added from reorder")
    })
    .catch(function(err){
      console.log(err)
    })
  }

  receiveMessage = (event) => {
    const message = event.data.message;
    switch (message) {
      case 'getLineItems':
        this.setState({
          line_items: event.data.value
        }, () => {
          this.getReOrderURL();
        });
        break;
      default:
        break;
    }
  }

  componentDidMount() {
    this.getShop().then(async() => {
      window.addEventListener("message", this.receiveMessage, false);
      this.getReOrder();
    });
  }

  render() {
    
    const renderer = ({ api, hours, minutes, seconds }) => {
      if(minutes < 10) minutes = "0"+minutes;
      if(seconds < 10) seconds = "0"+seconds;
      if(hours > 0) {
        return <span><Trans i18nKey="Available for" /> 0{hours}:{minutes}:{seconds}</span>;
      } else {
        return <span><Trans i18nKey="Available for" /> {minutes}:{seconds}</span>;
      }
    };

    return (
      <Col className="box-border" data-iframe-height="iFrameResizer">
        <Row className="reorder">
          <Col>
            <h4>{this.state.header}</h4>
            {this.state.showBtn ? (
              <a href={this.state.reorder_url} onClick={() => this.addConversion()} target="_blank" rel="noreferrer">
                <span className="hypecart-button">{this.state.button}</span>
              </a>
            ) : (
              <span className="hypecart-button disabled">{this.state.button}</span>
            )}
            {this.state.timer_time > 0 &&
              <span className="countdown">
                {this.state.showBtn ? (
                  <Countdown 
                    date={Date.now() + this.state.timer_time} 
                    renderer={renderer} 
                    onComplete={() => this.setState({ showBtn: false })}
                  />
                ) : (
                  <span><Trans i18nKey="Your offer has expired" /></span>
                )}
              </span>
            }
          </Col>
        </Row>
      </Col>
    );
  }
}

export default ReOrder;