export function formatCurrency(amount, currency) {
  
  amount = parseFloat(amount).toFixed(2);
  

  switch(currency) {
    case 'USD':
      amount = amount.replace(/\.00$/,'');
      return `$${amount}`;
    case 'EUR':
      amount = amount.replace(/\.00$/,'');
      return `${amount}€`;
    case 'AUD':
      amount = amount.replace(/\.00$/,'');
      return `$${amount}`;
    case 'GBP':
      amount = amount.replace(/\.00$/,'');
      return `£${amount}`;
    case 'COP':
      amount = amount.replace(/\.00$/,'');
      return `$${amount}`;
    case 'CAD':
      return `$${amount}`;
    case 'SEK':
      amount = amount.replace(/\.00$/,'');
      return `${amount} SEK`;
    default:
      return `${currency} ${amount}`;
  }
}

export default formatCurrency;