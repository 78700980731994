import React, { Fragment } from "react";
import {
  Col,
  Row
} from 'react-bootstrap';
import { Trans } from 'react-i18next';
import CrossSell from './CrossSell';
import Progressbar from './Progressbar';
import CartItemSkeleton from './CartItemSkeleton';
import formatCurrency from '../Helpers';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      currentSlide: 0,
      autoPlay: true
    };
  }

  changeSlide = (index) => {
    this.setState({currentSlide: index})
  }

  updateCurrentSlide = (index) => {
    if (this.state.currentSlide !== index) {
      this.setState({
          currentSlide: index,
      });
    }
  };

  componentDidMount() {
    //console.log(this.props.triggers)
  }

  render() {

    const discountedPrice = (price, discount_type, discount_value) => {
      let return_value;

      switch (discount_type) {
        case 'percentage':
          let difference = price * (discount_value/100)
          return_value = price - difference
          break;
        case 'fixed_amount':
          return_value = price - (discount_value*100)
          break;
        default:
          return_value = price;
          break;
      }


      return Math.round(return_value);
    }

    const crossSells = () => {
      if(this.props.group_offers) {
        return (
          <Fragment>
            <Carousel
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              autoPlay={(this.props.triggers.crossSells.filter(crossSell => crossSell.active).length < 2 ? false : true)}
              infiniteLoop={true}
              interval={4000}
              selectedItem={this.state.currentSlide}
              onChange={(index) => this.updateCurrentSlide(index)}
            >
              {this.props.triggers.crossSells.filter(crossSell => crossSell.active).map((crossSell, index) => {
              return (
                  <CrossSell
                    key={index}
                    show={crossSell.active ? true : false }
                    title={crossSell.title} 
                    subtitle={crossSell.subtitle} 
                    crossSellTriggers={crossSell.triggers}
                    total_cart_value={this.props.total_cart_value}
                    productHandle={crossSell.product_handle}
                    index={index}
                    handleState={(name, val) => this.props.handleState(name, val)}
                    addCrossSellProductToCart={(index) => this.props.addCrossSellProductToCart(index)}
                    {...this.props} 
                  />
              )  
            })

            }
          
          </Carousel>
          {this.props.triggers.crossSells.filter(crossSell => crossSell.active).length > 1 &&
          <ul className="control-dots">
          {this.props.triggers.crossSells.filter(crossSell => crossSell.active).map((crossSell, index) => {
            return (
                <li
                  key={index}
                  className={`dot ${(this.state.currentSlide === index ? 'selected' : '')}`}
                  value={index} 
                  role="button" 
                  aria-label={`slide item ${index}`}
                  onClick={() => this.changeSlide(index)}
                >
                </li>
            )
          })}
          </ul>  
        }
        </Fragment>
        )
        
      } else {
        return (
          <Fragment>
            {this.props.triggers.crossSells.map((crossSell, index) => {
            return (
                <CrossSell
                  key={index}
                  show={crossSell.active ? true : false }
                  title={crossSell.title} 
                  subtitle={crossSell.subtitle} 
                  crossSellTriggers={crossSell.triggers}
                  total_cart_value={this.props.total_cart_value}
                  productHandle={crossSell.product_handle}
                  index={index}
                  handleState={(name, val) => this.props.handleState(name, val)}
                  addCrossSellProductToCart={(index) => this.props.addCrossSellProductToCart(index)}
                  {...this.props} 
                />
            )  
          })
          }
          </Fragment>
        )
      }
      
      
    }

    let background_type;
    if(this.props.product_image_contain) {
      background_type = 'contain';
    } else {
      background_type = 'cover';
    }

    return (
      <Fragment>
        {this.props.loading ? (
          <CartItemSkeleton />
        ) : (
          <Fragment>
            {this.props.cart_items.map((item, index) => {
              return (
                  <Row 
                    key={index} 
                    className="cart-product" 
                    onClick={() => this.props.getProduct(item.handle, index)}
                  >
                    <Col xs={3} className="cart-img-outer">
                      <span className="cart-product-hover">
                        <Trans i18nKey="Edit item" />
                      </span>
                      <div className="cart-img" 
                        style={{  
                          backgroundImage: "url(" + item.image + ")",
                          backgroundPosition: 'center',
                          backgroundSize: background_type,
                          backgroundRepeat: 'no-repeat'
                        }}></div>
                    </Col>
                    <Col xs={6} className="meta">
                      <span>
                        <h3>
                          {item.product_title}
                        </h3>
                        {item.options_with_values[0].value !== "Default Title" &&
                          <h4>
                            {item.options_with_values.map((option, index) => {
                              return (
                                <Fragment key={index}>
                                  {option.name}: {option.value}&nbsp;
                                </Fragment>
                              )  
                            })}
                          </h4>
                        }
                        {this.props.shop == "miim-miic.myshopify.com" &&
                          <h4>Our compound of {item.product_type}</h4>
                        }
                        <h4>
                          <Trans i18nKey="Quantity" />: {item.quantity}
                        </h4>
                      </span>
                    </Col>
                    <Col xs={3} className="price">
                      {item.discount ? (
                        <Fragment>
                          <span className="old-price">
                            {formatCurrency(Number((item.price * item.quantity) / 100), this.props.currency)}
                          </span>
                          <span className="new-price">
                            {formatCurrency(Number((discountedPrice(item.price, item.discount_type, item.discount_value) * item.quantity) / 100), this.props.currency)}
                          </span>  
                        </Fragment>
                      ):(
                        <span>
                          {formatCurrency(Number((item.price * item.quantity) / 100), this.props.currency)}
                        </span>
                      )}
                    </Col>
                  </Row>
                
              )
            })}
          </Fragment>
        )}


        {this.props.triggers && (
          <Fragment>
            {this.props.offer_headline ? ( 
              <h2 className="offers"><Trans i18nKey="Offers for you" /></h2>
            ):(
              <div className="line">&nbsp;</div>
            )}

            {crossSells()}

            {this.props.triggers.progressBars.sort((a, b) => (a.progress > b.progress) ? -1 : 1).map((progressBar, index) => {
              return (
                <Fragment key={index}>
                  {progressBar.active && (
                    <Fragment>
                      {progressBar.progress < 100 &&
                        <Progressbar 
                          title={progressBar.title} 
                          completion={progressBar.completion} 
                          threshold={progressBar.threshold}
                          left_to_spend={progressBar.left_to_spend} 
                          progress={progressBar.progress}
                          progress_quantity={progressBar.progress_quantity}
                          progressbar={progressBar.progressbar}
                          subtitle={progressBar.subtitle}
                          image={progressBar.image}
                          type={progressBar.type}
                          gift={progressBar.gift}
                          gift_id={progressBar.gift_id}
                          total_cart_quantity={this.props.cart_items_number}
                          total_cart_value={this.props.total_cart_value}
                          {...this.props} 
                        />
                      }
                    </Fragment>
                  )}
                </Fragment>
              )  
            })}
            {this.props.triggers.progressBars.sort((a, b) => (a.progress > b.progress) ? -1 : 1).map((progressBar, index) => {
              return (
                <Fragment key={index}>
                  {progressBar.active && (
                    <Fragment>
                      {progressBar.progress >= 100 &&
                        <Progressbar 
                          title={progressBar.title} 
                          completion={progressBar.completion} 
                          threshold={progressBar.threshold}
                          left_to_spend={progressBar.left_to_spend} 
                          progress={progressBar.progress}
                          progress_quantity={progressBar.progress_quantity}
                          progressbar={progressBar.progressbar}
                          subtitle={progressBar.subtitle}
                          image={progressBar.image}
                          type={progressBar.type}
                          gift={progressBar.gift}
                          gift_id={progressBar.gift_id}
                          total_cart_quantity={this.props.cart_items_number}
                          total_cart_value={this.props.total_cart_value}
                          {...this.props} 
                        />
                      }
                    </Fragment>
                  )}
                </Fragment>
              )  
            })}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default Cart;

