import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: {
      "Your cart": "Your cart",
      "items": "items",
      "Offers for you": "Offers for you",
      "Add to cart": "Add to cart",
      "Add": "Add to cart", 
      "Subtotal": "Subtotal",
      "Checkout": "Checkout",
      "Variant": "Variant",
      "Quantity": "Quantity",
      "Edit item": "Edit item",
      "Save": "Save",
      "Back to cart": "Back to cart",
      "Remove from cart": "Remove from cart",
      "Add item": "Add item",
      "Preparing checkout": "Preparing checkout",
      "Your cart is empty": "Your cart is empty",
      "Note": "Add a note to your order",
      "Available for": "Available for",
      "Re-order now": "Re-order now",
      "Purchase now": "Purchase now",
      "Buy now": "Buy now",
      "Submit": "Submit",
      "January": "January",
      "February": "February",
      "March": "March",
      "April": "April",
      "May": "May",
      "June": "June",
      "July": "July",
      "August": "August",
      "September": "September",
      "October": "October",
      "November": "November",
      "December": "December",
      "Click to copy": "Click to copy",
      "Copied": "Copied",
      "Your offer has expired": "Your offer has expired",
      "Loading": "Loading",
      "Year": "Year",
      "Month": "Month",
      "Day": "Day"
    }
  },
  sv: {
    translation: {
      "Your cart": "Varukorg",
      "items": "varor",
      "Offers for you": "Erbjudanden",
      "Add to cart": "Lägg i varukorgen",
      "Add": "Lägg till",
      "Subtotal": "Total",
      "Checkout": "Gå till kassan",
      "Variant": "Variant",
      "Quantity": "Antal",
      "Edit item": "Ändra vara",
      "Save": "Spara",
      "Back to cart": "Tillbaka till varukorgen",
      "Remove from cart": "Ta bort från varukorgen",
      "Add item": "Lägg till vara",
      "Preparing checkout": "Förbereder kassan",
      "Your cart is empty": "Din varukorg är tom",
      "Note": "Lägg till en anteckning till ordern",
      "Available for": "Tillgänglig i",
      "Re-order now": "Beställ igen",
      "Purchase now": "Köp nu",
      "Buy now": "Köp nu",
      "Submit": "Skicka in",
      "January": "Januari",
      "February": "Februari",
      "March": "Mars",
      "April": "April",
      "May": "Maj",
      "June": "Juni",
      "July": "Juli",
      "August": "Augusti",
      "September": "September",
      "October": "Oktober",
      "November": "November",
      "December": "December",
      "Click to copy": "Klicka för att kopiera",
      "Copied": "Kopierat",
      "Your offer has expired": "Erbjudandet har gått ut",
      "Loading": "Laddar",
      "Year": "År",
      "Month": "Månad",
      "Day": "Dag"
    }
  },
  es: {
    translation: {
      "Your cart": "Carrito",
      "items": "productos",
      "Offers for you": "Ofertas",
      "Add to cart": "Añadir a la cesta",
      "Add": "Añadir", 
      "Subtotal": "Total",
      "Checkout": "Finalizar pedido",
      "Variant": "Variante",
      "Quantity": "Cantidad",
      "Edit item": "Cambiar producto",
      "Save": "Guardar",
      "Back to cart": "Volver a la cesta",
      "Remove from cart": "Eliminar",
      "Add item": "Añadir producto",
      "Preparing checkout": "Preparando trámite",
      "Your cart is empty": "Tu cesta está vacia",
      "Note": "Agregue una nota a su pedido",
      "Available for": "Disponible para",
      "Re-order now": "Volver a pedir ahora",
      "Purchase now": "Adquirir ahora",
      "Buy now": "Comprar ahora",
      "Submit": "Enviar",
      "January": "Enero",
      "February": "Febrero",
      "March": "Marzo",
      "April": "Abril",
      "May": "Mayo",
      "June": "Junio",
      "July": "Julio",
      "August": "Agosto",
      "September": "Septiembre",
      "October": "Octubre",
      "November": "Noviembre",
      "December": "Diciembre",
      "Click to copy": "Haga clic para copiar",
      "Copied": "Copiada",
      "Your offer has expired": "Su oferta ha caducado",
      "Loading": "Cargando",
      "Year": "Año",
      "Month": "Mes",
      "Day": "Día"
    }
  },
  fr: {
    translation: {
      "Your cart": "Votre panier",
      "items": "articles",
      "Offers for you": "Offres pour vous",
      "Add to cart": "Ajouter au panier",
      "Add": "Ajouter", 
      "Subtotal": "Sous-total",
      "Checkout": "Paiement",
      "Variant": "Variante",
      "Quantity": "Quantité",
      "Edit item": "Modifier l'article",
      "Save": "Sauvegarder",
      "Back to cart": "Retour au panier",
      "Remove from cart": "Remove from cart",
      "Add item": "Ajouter un article",
      "Preparing checkout": "Préparation du paiement",
      "Your cart is empty": "Votre panier est vide",
      "Note": "Ajouter une note à votre commande",
      "Available for": "Disponible pour",
      "Re-order now": "Renouveler la commande",
      "Purchase now": "Acheter maintenant",
      "Buy now": "Acheter maintenant",
      "Submit": "Soumettre",
      "January": "Janvier",
      "February": "Février",
      "March": "Mars",
      "April": "Avril",
      "May": "Mai",
      "June": "Juin",
      "July": "Juillet",
      "August": "Août",
      "September": "Septembre",
      "October": "Octobre",
      "November": "Novembre",
      "December": "Décembre",
      "Click to copy": "Cliquez pour copier",
      "Copied": "Copié",
      "Your offer has expired": "Votre offre a expiré",
      "Loading": "Chargement",
      "Year": "Année",
      "Month": "Mois",
      "Day": "Jour"
    }
  },
  de: {
    translation: {
      "Your cart": "Ihr Einkaufswagen",
      "items": "Artikel",
      "Offers for you": "Angebote für Sie",
      "Add to cart": "In den Einkaufswagen legen",
      "Add": "Hinzufügen", 
      "Subtotal": "Zwischensumme",
      "Checkout": "Zur Kasse",
      "Variant": "Variante",
      "Quantity": "Menge",
      "Edit item": "Artikel bearbeiten",
      "Save": "Speichern",
      "Back to cart": "Zurück zum Einkaufswagen",
      "Remove from cart": "Aus dem Warenkorb entfernen",
      "Add item": "Artikel hinzufügen",
      "Preparing checkout": "Bezahlen wird vorbereitet",
      "Your cart is empty": "Ihr Warenkorb ist leer",
      "Note": "Einen Hinweis zu Ihrer Bestellung hinzufügen",
      "Available for": "Jetzt verfügbar",
      "Re-order now": "zum Nachbestellen",
      "Purchase now": "Jetzt erwerben",
      "Buy now": "Jetzt kaufen",
      "Submit": "Absenden",
      "January": "Januar",
      "February": "Februar",
      "March": "März",
      "April": "April",
      "May": "Mai",
      "June": "Juni",
      "July": "Juli",
      "August": "August",
      "September": "September",
      "October": "Oktober",
      "November": "November",
      "December": "Dezember",
      "Click to copy": "Zum Kopieren klicken",
      "Copied": "Kopiert",
      "Your offer has expired": "Ihr angebot ist abgelaufen",
      "Loading": "Wird geladen",
      "Year": "Jahr",
      "Month": "Monat",
      "Day": "Tag"
    }
  },
  de_in: {
    translation: {
      "Your cart": "Dein Warenkorb",
      "items": "Artikel",
      "Offers for you": "Angebote für dich",
      "Add to cart": "In den Warenkorb legen",
      "Add": "Hinzufügen", 
      "Subtotal": "Zwischensumme",
      "Checkout": "Zur Kasse",
      "Variant": "Variante",
      "Quantity": "Menge",
      "Edit item": "Artikel bearbeiten",
      "Save": "Speichern",
      "Back to cart": "Zurück zum Warenkorb",
      "Remove from cart": "Aus dem Warenkorb entfernen",
      "Add item": "Artikel hinzufügen",
      "Preparing checkout": "Bezahlen wird vorbereitet",
      "Your cart is empty": "Dein Warenkorb ist leer",
      "Note": "Einen Hinweis zu deiner Bestellung hinzufügen",
      "Available for": "Jetzt verfügbar",
      "Re-order now": "zum Nachbestellen",
      "Purchase now": "Jetzt erwerben",
      "Buy now": "Jetzt kaufen",
      "Submit": "Absenden",
      "January": "Januar",
      "February": "Februar",
      "March": "März",
      "April": "April",
      "May": "Mai",
      "June": "Juni",
      "July": "Juli",
      "August": "August",
      "September": "September",
      "October": "Oktober",
      "November": "November",
      "December": "Dezember",
      "Click to copy": "Zum Kopieren klicken",
      "Copied": "Kopiert",
      "Your offer has expired": "Deine angebot ist abgelaufen",
      "Loading": "Wird geladen",
      "Year": "Jahr",
      "Month": "Monat",
      "Day": "Tag"
    }
  },
  pt: {
    translation: {
      "Your cart": "Seu carrinho",
      "items": "itens",
      "Offers for you": "Ofertas para você",
      "Add to cart": "Adicionar ao carrinho",
      "Add": "Adicionar", 
      "Subtotal": "Subtotal",
      "Checkout": "Pagamento",
      "Variant": "Variante",
      "Quantity": "Quantidade",
      "Edit item": "Editar item",
      "Save": "Salvar",
      "Back to cart": "Voltar ao carrinho",
      "Remove from cart": "Remove from cart",
      "Add item": "Adicionar Item",
      "Preparing checkout": "Preparando pagamento",
      "Your cart is empty": "Seu carrinho está vazio",
      "Note": "Adicione um comentário à sua encomenda",
      "Available for": "Disponível por",
      "Re-order now": "Voltar a encomendar agora",
      "Purchase now": "Obter agora",
      "Buy now": "Comprar agora",
      "Submit": "Enviar",
      "January": "Janeiro",
      "February": "Fevereiro",
      "March": "Março",
      "April": "Abril",
      "May": "Maio",
      "June": "Junho",
      "July": "Julho",
      "August": "Agosto",
      "September": "Setembro",
      "October": "Outubro",
      "November": "Novembro",
      "December": "Dezembro",
      "Click to copy": "Clique para copiar",
      "Copied": "Copiada",
      "Your offer has expired": "A sua oferta expirou",
      "Loading": "A carregar",
      "Year": "Ano",
      "Month": "Mês",
      "Day": "Dia"
    }
  },
  pt_eu: {
    translation: {
      "Your cart": "O seu carrinho",
      "items": "itens",
      "Offers for you": "Ofertas para si",
      "Add to cart": "Adicionar ao carrinho",
      "Add": "Adicionar", 
      "Subtotal": "Subtotal",
      "Checkout": "Finalizar a compra",
      "Variant": "Variante",
      "Quantity": "Quantidade",
      "Edit item": "Editar item",
      "Save": "Guardar",
      "Back to cart": "Voltar ao carrinho",
      "Remove from cart": "Remove from cart",
      "Add item": "Adicionar Item",
      "Preparing checkout": "A preparar a finalização da compra",
      "Your cart is empty": "O seu carrinho está vazio",
      "Note": "Adicione uma nota à sua encomenda",
      "Available for": "Disponível durante",
      "Re-order now": "Voltar a encomendar agora",
      "Purchase now": "Comprar agora",
      "Buy now": "Comprar agora",
      "Submit": "Enviar",
      "January": "Janeiro",
      "February": "Fevereiro",
      "March": "Março",
      "April": "Abril",
      "May": "Maio",
      "June": "Junho",
      "July": "Julho",
      "August": "Agosto",
      "September": "Setembro",
      "October": "Outubro",
      "November": "Novembro",
      "December": "Dezembro",
      "Click to copy": "Clique para copiar",
      "Copied": "Copiado",
      "Your offer has expired": "A sua oferta expirou",
      "Loading": "A carregar",
      "Year": "Ano",
      "Month": "Mês",
      "Day": "Dia"
    }
  },
  ru: {
    translation: {
      "Your cart": "Ваша корзина",
      "items": "Товары",
      "Offers for you": "Предложения для вас",
      "Add to cart": "Добавить в корзину",
      "Add": "Добавлять", 
      "Subtotal": "Промежуточный итог",
      "Checkout": "Расчет",
      "Variant": "Вариант",
      "Quantity": "Количество",
      "Edit item": "Редактировать товар",
      "Save": "Сохранить",
      "Back to cart": "Обратно в корзину",
      "Remove from cart": "Remove from cart",
      "Add item": "Добавить товар",
      "Preparing checkout": "Подготовка расчета",
      "Your cart is empty": "Ваша корзина пуста",
      "Note": "Добавить примечание к заказу",
      "Available for": "Доступно для",
      "Re-order now": "Заказать повторно сейчас",
      "Purchase now": "Приобрести сейчас",
      "Buy now": "Купить сейчас",
      "Submit": "Отправить",
      "January": "Январь",
      "February": "Февраль",
      "March": "Март",
      "April": "Апрель",
      "May": "Май",
      "June": "Июнь",
      "July": "Июль",
      "August": "Август",
      "September": "Сентябрь",
      "October": "Октябрь",
      "November": "Ноябрь",
      "December": "Декабрь",
      "Click to copy": "Нажмите, чтобы скопировать",
      "Copied": "Скопировано",
      "Your offer has expired": "Ваше предложение истекло",
      "Loading": "Загрузка",
      "Year": "Год",
      "Month": "Месяц",
      "Day": "День"
    }
  },
  it: {
    translation: {
      "Your cart": "Il Tuo Carrello",
      "items": "Articoli",
      "Offers for you": "Offerte per te",
      "Add to cart": "Aggiungi al carrello",
      "Add": "Aggiungi", 
      "Subtotal": "Totale parziale",
      "Checkout": "Checkout",
      "Variant": "Variante",
      "Quantity": "Quantità",
      "Edit item": "Modifica articolo",
      "Save": "Salva",
      "Back to cart": "Torna al carrello",
      "Remove from cart": "Remove from cart",
      "Add item": "Aggiungi articolo",
      "Preparing checkout": "Preparazione al pagamento",
      "Your cart is empty": "il tuo carrello è vuoto",
      "Note": "Aggiungi una nota al tuo ordine",
      "Available for": "Disponibile per",
      "Re-order now": "Riordina adesso",
      "Purchase now": "Acquista adesso",
      "Buy now": "Acquista ora",
      "Submit": "Invia",
      "January": "Gennaio",
      "February": "Febbraio",
      "March": "Marzo",
      "April": "Aprile",
      "May": "Maggio",
      "June": "Giugno",
      "July": "Luglio",
      "August": "Agosto",
      "September": "Settembre",
      "October": "Ottobre",
      "November": "Novembre",
      "December": "Dicembre",
      "Click to copy": "Clicca per copiare",
      "Copied": "copiato",
      "Your offer has expired": "La tua offerta è scaduta",
      "Loading": "Caricamento in corso",
      "Year": "Anno",
      "Month": "Mese",
      "Day": "Giorno"
    }
  },
  jp: {
    translation: {
      "Your cart": "あなたのカート",
      "items": "商品",
      "Offers for you": "あなたへのオファー",
      "Add to cart": "カートに入れる",
      "Add": "追加", 
      "Subtotal": "小計",
      "Checkout": "会計",
      "Variant": "種類",
      "Quantity": "数量",
      "Edit item": "商品の変更",
      "Save": "保存",
      "Back to cart": "カートに戻る",
      "Remove from cart": "Remove from cart",
      "Add item": "商品を追加",
      "Preparing checkout": "会計の準備",
      "Your cart is empty": "カートは空です",
      "Note": "注文にメモを追加する",
      "Available for": "販売期間：",
      "Re-order now": "今すぐ再注文",
      "Purchase now": "今すぐ購入",
      "Buy now": "今すぐ購入",
      "Submit": "送信",
      "January": "1月",
      "February": "2月",
      "March": "3月",
      "April": "4月",
      "May": "5月",
      "June": "6月",
      "July": "7月",
      "August": "8月",
      "September": "9月",
      "October": "10月",
      "November": "11月",
      "December": "12月",
      "Click to copy": "クリックしてコピー",
      "Copied": "コピー",
      "Your offer has expired": "オファーが期限切れになりました",
      "Loading": "読み込み中",
      "Year": "年",
      "Month": "月",
      "Day": "日"
    }
  },
  ch: {
    translation: {
      "Your cart": "您的购物车",
      "items": "商品",
      "Offers for you": "为您提供的优惠",
      "Add to cart": "添加到购物车",
      "Add": "添加", 
      "Subtotal": "小计",
      "Checkout": "结账",
      "Variant": "种类",
      "Quantity": "数量",
      "Edit item": "编辑商品",
      "Save": "保存",
      "Back to cart": "返回到购物车",
      "Remove from cart": "Remove from cart",
      "Add item": "添加商品",
      "Preparing checkout": "准备结账",
      "Your cart is empty": "您的购物车是空的",
      "Note": "为订单添加备注",
      "Available for": "可用于",
      "Re-order now": "立即再订购",
      "Purchase now": "立即采购",
      "Buy now": "立即购买",
      "Submit": "提交",
      "January": "1 月",
      "February": "2 月",
      "March": "3 月",
      "April": "4 月",
      "May": "5 月",
      "June": "6 月",
      "July": "7 月",
      "August": "8 月",
      "September": "9 月",
      "October": "10 月",
      "November": "11 月",
      "December": "12 月",
      "Click to copy": "单击以复制",
      "Copied": "已复制",
      "Your offer has expired": "优惠已过期",
      "Loading": "正在加载",
      "Year": "年",
      "Month": "月",
      "Day": "日"
    }
  },
  dk: {
    translation: {
      "Your cart": "Din kurv",
      "items": "Varer",
      "Offers for you": "TIlbud til dig",
      "Add to cart": "Tilføj til kurv",
      "Add": "Tilføj", 
      "Subtotal": "Total",
      "Checkout": "Gå til betaling",
      "Variant": "Variant",
      "Quantity": "Antal",
      "Edit item": "Rediger vare",
      "Save": "Gem",
      "Back to cart": "Tilbage til kurv",
      "Remove from cart": "Remove from cart",
      "Add item": "TIlføj vare",
      "Preparing checkout": "Forbereder betaling",
      "Your cart is empty": "Din kurv er tom",
      "Note": "Føj en bemærkning til din ordre",
      "Available for": "Tilgængelig for",
      "Re-order now": "Genbestil nu",
      "Purchase now": "Køb nu",
      "Buy now": "Køb nu",
      "Submit": "Indsend",
      "January": "Januar",
      "February": "Februar",
      "March": "Marts",
      "April": "April",
      "May": "Maj",
      "June": "Juni",
      "July": "Juli",
      "August": "August",
      "September": "September",
      "October": "Oktober",
      "November": "November",
      "December": "December",
      "Click to copy": "Klik for at kopiere",
      "Copied": "Kopieret",
      "Your offer has expired": "Dit tilbud er udløbet",
      "Loading": "Indlæser",
      "Year": "År",
      "Month": "Måned",
      "Day": "Dag"
    }
  },
  is: {
    translation: {
      "Your cart": "Karfan þín",
      "items": "Vörur",
      "Offers for you": "Vörur í boði fyrir þig",
      "Add to cart": "Bæta í körfu",
      "Add": "Bæta við", 
      "Subtotal": "Samtals",
      "Checkout": "Ganga frá greiðslu",
      "Variant": "Afbrigði",
      "Quantity": "Magn",
      "Edit item": "Breyta vöru",
      "Save": "Vista",
      "Back to cart": "Aftur í körfu",
      "Remove from cart": "Remove from cart",
      "Add item": "Bæta vöru við ",
      "Preparing checkout": "Undirbý afgreiðslu",
      "Your cart is empty": "Karfan þín er tóm",
      "Note": "Bættu skilaboðum við pöntunina þína",
      "Available for": "Available for",
      "Re-order now": "Re-order now",
      "Purchase now": "Purchase now",
      "Buy now": "Buy now",
      "Submit": "Submit",
      "January": "January",
      "February": "February",
      "March": "March",
      "April": "April",
      "May": "May",
      "June": "June",
      "July": "July",
      "August": "August",
      "September": "September",
      "October": "October",
      "November": "November",
      "December": "December",
      "Click to copy": "Click to copy",
      "Copied": "Afritað",
      "Your offer has expired": "Your offer has expired",
      "Loading": "Loading",
      "Year": "Year",
      "Month": "Month",
      "Day": "Day"
    }
  },
  lv: {
    translation: {
      "Your cart": "Jūsu Grozs",
      "items": "Prece(-s)",
      "Offers for you": "Jums varētu interesēt:",
      "Add to cart": "Ielikt Grozā",
      "Add": "Pievienot", 
      "Subtotal": "Starpsumma",
      "Checkout": "Uz Apmaksu",
      "Variant": "Variants",
      "Quantity": "Daudzums",
      "Edit item": "Rediģēt",
      "Save": "Saglabāt",
      "Back to cart": "Atpakaļ uz Grozu",
      "Remove from cart": "Remove from cart",
      "Add item": "Pievienot Preci",
      "Preparing checkout": "Sagatavojam apmaksu",
      "Your cart is empty": "Jūsu grozs ir tukšs",
      "Note": "Pievienot komentāru pasūtījumam",
      "Available for": "Pieejams par",
      "Re-order now": "Pasūtīt atkārtoti tagad",
      "Purchase now": "Pasūtīt tagad",
      "Buy now": "Pirkt tagad",
      "Submit": "Iesniegt",
      "January": "Janvāris",
      "February": "Februāris",
      "March": "Marts",
      "April": "Aprīlis",
      "May": "Maijs",
      "June": "Jūnijs",
      "July": "Jūlijs",
      "August": "Augusts",
      "September": "Septembris",
      "October": "Oktobris",
      "November": "Novembris",
      "December": "Decembris",
      "Click to copy": "Nospiediet, lai kopētu",
      "Copied": "Kopēts",
      "Your offer has expired": "Jūsu pasūtījums ir beidzies",
      "Loading": "Ielādē",
      "Year": "Gads",
      "Month": "Mēnesis",
      "Day": "Diena"
    }
  },
  lt: {
    translation: {
      "Your cart": "Jūsų pirkinių krepšelis",
      "items": "Prekės",
      "Offers for you": "Pasiūlymas jums",
      "Add to cart": "Įdėti į krepšelį",
      "Add": "Įdėti", 
      "Subtotal": "Tarpinė suma",
      "Checkout": "Apmokėti",
      "Variant": "Pasirinkimas",
      "Quantity": "Kiekis",
      "Edit item": "Keisti",
      "Save": "Išsaugoti",
      "Back to cart": "Grįžti į krepšelį",
      "Remove from cart": "Pašalinti iš krepšelio",
      "Add item": "Pridėti",
      "Preparing checkout": "Pasiruošti apmokėjimui",
      "Your cart is empty": "Jūsų pirkinių krepšelis tuščias",
      "Note": "Pridėkite savo žinutę pardavėjui",
      "Available for": "Galima įsigyti",
      "Re-order now": "Pakartotinai užsisakyti iš naujo",
      "Purchase now": "Įsigykite dabar",
      "Buy now": "Pirkti dabar",
      "Submit": "Pateikite",
      "January": "Sausis",
      "February": "Vasaris",
      "March": "Kovas",
      "April": "Balandis",
      "May": "Gegužė",
      "June": "Birželis",
      "July": "Liepa",
      "August": "Rugpjūtis",
      "September": "Rugsėjis",
      "October": "Spalis",
      "November": "Lapkritis",
      "December": "Gruodis",
      "Click to copy": "Spustelėkite, jei norite kopijuoti",
      "Copied": "Nukopijuota",
      "Your offer has expired": "Jūsų pasiūlymas baigėsi",
      "Loading": "Kraunama",
      "Year": "Metai",
      "Month": "Mėnuo",
      "Day": "Diena"
    }
  },
  no: {
    translation: {
      "Your cart": "Din handlevogn",
      "items": "Varer",
      "Offers for you": "Ofte kjøpt sammen",
      "Add to cart": "Legg i handlekurv",
      "Add": "Legg til", 
      "Subtotal": "Subtotalt",
      "Checkout": "Fortsett til kassen",
      "Variant": "Variant",
      "Quantity": "Antall",
      "Edit item": "Endre varen",
      "Save": "Lagre",
      "Back to cart": "Tilbake til handlekurven",
      "Remove from cart": "Fjern fra handlekurven",
      "Add item": "Legg til vare",
      "Preparing checkout": "Omdirigerer til kassen...",
      "Your cart is empty": "Din handlevogn er tom",
      "Note": "Legg et notat til din ordre",
      "Available for": "Tilgjengelig for",
      "Re-order now": "Bestill på nytt nå",
      "Purchase now": "Kjøp nå",
      "Buy now": "Kjøp nå",
      "Submit": "Sende inn",
      "January": "januar",
      "February": "februar",
      "March": "mars",
      "April": "april",
      "May": "mai",
      "June": "juni",
      "July": "juli",
      "August": "august",
      "September": "september",
      "October": "oktober",
      "November": "november",
      "December": "desember",
      "Click to copy": "Klikk for å kopiere",
      "Copied": "Kopiert",
      "Your offer has expired": "Ditt tilbud har utløpt",
      "Loading": "Lastes",
      "Year": "År",
      "Month": "Måned",
      "Day": "Dag"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;
