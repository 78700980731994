import React, { Fragment } from "react";
import {
  Col,
  Row,
  Button,
  InputGroup,
  FormControl,
  Form,
} from 'react-bootstrap';
import { Trans } from 'react-i18next';
import _ from 'lodash';
import axios from "axios";
import formatCurrency from '../Helpers';


class CartSingleProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backend_url: process.env.REACT_APP_BACKEND_URL,
      inventory: null,
      showdiscount: false
    };
  }

  getInventory = async () => {
    axios.post(this.state.backend_url+'/cart/inventory', {
      shop: this.props.shop,
      product_id: this.props.cart_items[this.props.product_index].product_id
    }).then((response) => {
      this.setState({ inventory: response.data })
      return true;
    }).catch((err) => {
      console.log(err)
    });
  }

  changeQuantity = (type) => {
    let selected_variant = this.props.cart_items[this.props.product_index].variant_id;
    let limit = _.find(this.state.inventory, ['id', parseInt(selected_variant)]);
    
    if(limit.inventory_management === null) {
      limit = 9999999999;
    } else {
      limit = limit.inventory_quantity;
    }
    
    if(type === "add") {
      let cart_items = [...this.props.cart_items]
      let item = {...cart_items[this.props.product_index]}
      if(item.quantity < limit) {
        item.quantity = item.quantity+1
        cart_items[this.props.product_index] = item
        this.props.handleState('cart_items', cart_items).then(() => {
          this.checkChanges();
        });
      }
    } else if(type === "remove") {
      if(this.props.cart_items[this.props.product_index].quantity !== 0) {
        let cart_items = [...this.props.cart_items]
        let item = {...cart_items[this.props.product_index]}
        item.quantity = item.quantity-1
        cart_items[this.props.product_index] = item
        this.props.handleState('cart_items', cart_items).then(() => {
          this.checkChanges();
        });
      }
    } else {
      let cart_items = [...this.props.cart_items]
      let item = {...cart_items[this.props.product_index]}
      item.quantity = type
      cart_items[this.props.product_index] = item
      this.props.handleState('cart_items', cart_items).then(() => {
        this.checkChanges();
      });
    }
  }

  onVariantChange = (event) => {
    let newVariant = event.target.value;
    this.props.handleState('variant', newVariant);

    if(this.props.cart_items[this.props.product_index].product_type === "Free Gift") {
      this.props.handleState('free_gift_variant', newVariant);
    }

    let cart_items = [...this.props.cart_items]
    let item = {...cart_items[this.props.product_index]}
    let newVariantObject = _.find(this.props.current_product.variants, ['id', parseInt(newVariant)]);
    
    let image;
    if(newVariantObject.featured_image !== null) {
      image = newVariantObject.featured_image.src;
    } else {
      image = this.state.backup_image;
    }

    this.setState({
      price: newVariantObject.price,
      image: image
    });
    
    let index = 0;
    for(const option of item.options_with_values) {
      option.value = newVariantObject.options[index];
      index++;
    }
    item.variant_id = newVariant;

    let limit = _.find(this.state.inventory, ['id', parseInt(newVariant)]);
    if(limit.inventory_quantity > 0) {
      limit = limit.inventory_quantity;
    } else {
      limit = 1;
    }
    let quantity = this.props.cart_items[this.props.product_index].quantity;
    if(quantity > limit) {
      item.quantity = limit;
    }

    cart_items[this.props.product_index] = item
    this.props.handleState('cart_items', cart_items).then(() => {
      this.checkChanges();
    });
    
  }

  checkChanges = () => {
    let og_variant = this.props.og_cart_items[this.props.product_index].variant_id;
    let og_quantity = this.props.og_cart_items[this.props.product_index].quantity;
    let variant = this.props.cart_items[this.props.product_index].variant_id;
    let quantity = this.props.cart_items[this.props.product_index].quantity;
    
    if(parseInt(og_variant) !== parseInt(variant) || parseInt(og_quantity) !== parseInt(quantity)) {
      this.props.handleState('showSave', true);
    } else {
      this.props.handleState('showSave', false);
    }
  }

  componentDidMount = async ()  => {
    await this.getInventory();
    this.setState({
      price: this.props.cart_items[this.props.product_index].price,
      image: this.props.cart_items[this.props.product_index].featured_image.url,
      backup_image: this.props.current_product.featured_image
    })
  }

  render() {
    let background_type;
    if(this.props.product_image_contain) {
      background_type = 'contain';
    } else {
      background_type = 'cover';
    }

    const discountedPrice = (price, discount_type, discount_value) => {
      let return_value;

      switch (discount_type) {
        case 'percentage':
          let difference = price * (discount_value/100)
          return_value = price - difference
          break;
        case 'fixed_amount':
          return_value = price - (discount_value*100)
          break;
        default:
          return_value = price;
          break;
      }
      return return_value;
    }
    
    return (
      <Fragment>
        {this.props.cart_items[this.props.product_index] &&
        <Fragment>
            <Row className="cart-product">
              <Col xs={3} className="cart-img-outer">
                <div className="cart-img bababa" 
                  style={{  
                    backgroundImage: "url(" + this.state.image + ")",
                    backgroundPosition: 'center',
                    backgroundSize: background_type,
                    backgroundRepeat: 'no-repeat'
                  }}></div>
              </Col>
              <Col xs={6} className="meta">
                <span>
                  <h3>
                  {this.props.cart_items[this.props.product_index].product_title}
                  </h3>
                  {this.props.current_product.variants[0].title !== "Default Title" &&
                    <h4>
                      {this.props.cart_items[this.props.product_index].options_with_values.map((option, index) => {
                        return (
                          <Fragment key={index}>
                            {option.name}: {option.value}&nbsp;
                          </Fragment>
                        )  
                      })}
                    </h4>
                  }
                  <h4>
                    <Trans i18nKey="Quantity" />: {this.props.cart_items[this.props.product_index].quantity}
                  </h4>
                </span>
              </Col>
              <Col xs={3} className="price">
                {this.props.cart_items[this.props.product_index].discount ? (
                  <Fragment>
                    <span className="old-price">
                      {formatCurrency(Number((this.state.price * this.props.cart_items[this.props.product_index].quantity) / 100), this.props.currency)}
                    </span>
                    <span className="new-price">
                      {formatCurrency(Number((discountedPrice(this.state.price, this.props.cart_items[this.props.product_index].discount_type, this.props.cart_items[this.props.product_index].discount_value) * this.props.cart_items[this.props.product_index].quantity) / 100), this.props.currency)}
                    </span>  
                  </Fragment>
                ):(
                  <span>
                    {formatCurrency(Number((this.state.price * this.props.cart_items[this.props.product_index].quantity) / 100), this.props.currency)}
                  </span>
                )}
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center cart-edit">
              <Col xs={6}>
                <h3><Trans i18nKey="Quantity" /></h3>
                {this.props.cart_items[this.props.product_index].product_type !== "Free Gift" ? (
                  <InputGroup>
                    <InputGroup.Prepend>
                      <Button onClick={() => this.changeQuantity("remove")} variant="outline-secondary" className="remove">-</Button>
                    </InputGroup.Prepend>
                    <FormControl className="quantity" onChange={(e) => this.changeQuantity(e.target.value)} value={this.props.cart_items[this.props.product_index].quantity} />
                    <InputGroup.Append>
                      <Button onClick={() => this.changeQuantity("add")} variant="outline-secondary" className="add">+</Button>
                    </InputGroup.Append>
                  </InputGroup>
                ):(
                  <InputGroup>
                    <InputGroup.Prepend>
                      <Button disabled variant="outline-secondary" className="remove">-</Button>
                    </InputGroup.Prepend>
                    <FormControl onChange={() => console.log("This is a free gift, you cannot change the quantity :)")} className="quantity" value={this.props.cart_items[this.props.product_index].quantity} />
                    <InputGroup.Append>
                      <Button disabled variant="outline-secondary" className="add">+</Button>
                    </InputGroup.Append>
                  </InputGroup>
                )}
                
              </Col>
              <Col xs={6}>
              {this.props.current_product.variants[0].title !== "Default Title" &&
                <Fragment>
                <h3><Trans i18nKey="Variant" /></h3>
                  <Form.Control as="select" onChange={(event) => this.onVariantChange(event)} value={this.props.variant}>
                    {
                      this.props.current_product.variants.map((option, index) => {
                        return (
                          <option key={index} value={option.id}>{option.title}</option>
                        )
                      })
                    }
                  </Form.Control>
                </Fragment>
              }
              </Col>
            </Row>
          </Fragment>
        }
      </Fragment>
    );
  }
}

export default CartSingleProduct;

