import React, {Fragment} from "react";
import {
  Col,
  Row,
} from 'react-bootstrap';
import axios from "axios";
import sendMessage from '../SendMessage';
import i18next from "i18next";
import { Trans } from 'react-i18next';
import formatCurrency from '../../Helpers';
import Countdown from 'react-countdown';

class ProductUpsell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backend_url: process.env.REACT_APP_BACKEND_URL,
      shop: null,
      header: '',
      button: '',
      discount: false,
      discount_percentage: 0,
      product_id: 0,
      product: {},
      product_done: false,
      product_variant: '',
      product_price: 0,
      variant_order: 0,
      currency: '',
      checkout_url: null,
      loadCheckout: false,
      timer_time: 0,
      showBtn: true,
    };
  }

  getShop = async () => {
    function getQueryStringParams(query) {
      return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
    };
    let search = getQueryStringParams(window.location.search);
    if(search.shop) {
      this.setState({
        shop: search.shop,
        currency: search.currency
      });
      return true;
    }
  }

  getProductUpsell = () => {
     axios.post(this.state.backend_url+'/widgets/get-product-upsell', {shop: this.state.shop})
      .then((response) => {
        let timerTime = 60*response.data.widget.timer_time*1000;
        this.setState({
          header: response.data.widget.header,
          button: response.data.widget.button,
          timer: response.data.widget.timer,
          timer_time: timerTime,
          discount: response.data.widget.discount,
          discount_percentage: response.data.widget.discount_percentage,
          product_id: response.data.widget.product_id,
          lang: response.data.lang
        }, () => {
          if(this.state.lang !== 'en') {
            i18next.changeLanguage(this.state.lang, (err, t) => {
              if (err) return console.log('something went wrong loading', err);
            });
          }
          this.getProductInfo();
        });
     });
  };

  getProductInfo = () => {
    axios.post(this.state.backend_url+'/widgets/get-product-upsell-product', {
      shop: this.state.shop,
      product_id: this.state.product_id
    })
    .then((response) => {
      let variant;
      if(response.data.variants[0].title === 'Default Title') {
        variant = '';
      } else {
        variant = response.data.variants[0].title;
      }
      this.setState({
        product: response.data,
        product_price: response.data.variants[0].price,
        product_variant: variant,
        product_done: true
      }, () => {
        sendMessage('widgetDone');
      });
    });
  };

  addConversion = () => {
    axios.post(this.state.backend_url+'/conversions/create', {
      shop: this.state.shop,
      cart_token: this.state.cart_token,
      conversion_id: this.state.conversion_id,
      product_id: this.state.product_id
    }).then(function(res) {
      console.log("Conversion added from reorder")
    })
    .catch(function(err){
      console.log(err)
    })
  }

  getProductUpsellURL = () => {
    this.setState({loadCheckout: true});
    let variant_id = this.state.product.variants[this.state.variant_order].id;
    let line_items = [{
      variant_id: variant_id,
      quantity: 1
    }];
    axios.post(this.state.backend_url+'/widgets/get-product-upsell-url', {
      shop: this.state.shop,
      line_items: line_items,
      discount: this.state.discount,
      discount_percentage: this.state.discount_percentage
    })
    .then((response) => {
      console.log(response.data)
      let checkout_url = response.data.checkout_url;
      this.setState({
        cart_token: response.data.cart_token,
        conversion_id: "upsell widget",
        product_id: response.data.product_id
      }, () => {
        window.open(checkout_url);
        this.addConversion();
        this.setState({loadCheckout: false});
      })
    });
  };

  handleVariantChange = (event) => {
    let val = event.target.value;
    let variant = this.state.product.variants[val].title;
    let price = this.state.product.variants[val].price;
    this.setState({
      variant_order: val,
      product_variant: variant,
      product_price: price
    });
  };

  componentDidMount() {
    this.getShop().then(async() => {
      window.addEventListener("message", this.receiveMessage, false);
      this.getProductUpsell();
    });
  }

  render() {
    let priceClassName;
    if(!this.state.discount) priceClassName = "original-price";
    if(this.state.discount) priceClassName = "original-price discount";

    const getDiscountPrice = (price) => {
      let discount = Number(100-this.state.discount_percentage)/100;
      let discount_price = price*discount;
      return discount_price.toFixed(2);
    };

    const renderer = ({ api, hours, minutes, seconds }) => {
      if(minutes < 10) minutes = "0"+minutes;
      if(seconds < 10) seconds = "0"+seconds;
      if(hours > 0) {
        return <span><Trans i18nKey="Available for" /> 0{hours}:{minutes}:{seconds}</span>;
      } else {
        return <span><Trans i18nKey="Available for" /> {minutes}:{seconds}</span>;
      }
    };

    return (
      <Col className="box-border" data-iframe-height="iFrameResizer">
        <Row className="productupsell">
          <Col>
            <h4>{this.state.header}</h4>
            {this.state.product_done &&
              <Fragment>
                <div className="image">
                  <div className="product-thumbnail">
                    <img alt={this.state.product.title} src={this.state.product.image.src} />
                  </div>
                </div>
                <div className="text">
                  <span className="title">{this.state.product.title}</span>
                  <span className="variant">{this.state.product_variant}</span>
                </div>
                <div className="price">
                  <span className={priceClassName} >
                    {formatCurrency(this.state.product_price, this.state.currency)}
                  </span>
                  {this.state.discount &&
                    <span className="discount-price">
                      {formatCurrency(getDiscountPrice(this.state.product_price), this.state.currency)}
                    </span>
                  }
                </div>
                {this.state.product_variant !== '' &&
                  <select onChange={(event) => this.handleVariantChange(event)}>
                    {this.state.product.variants.map((item, index) => {
                      return (
                        <option value={index} key={index}>{item.title}</option>
                      )
                    })}
                  </select>
                }

                {this.state.showBtn ? (
                  <Fragment>
                    {this.state.loadCheckout ? (
                      <span className="hypecart-button disabled">
                        <Trans i18nKey="Loading" />
                      </span>
                    ) : (
                      <span className="hypecart-button" onClick={() => this.getProductUpsellURL()}>{this.state.button}</span>
                    )
                    }
                  </Fragment>
                ) : (
                  <span className="hypecart-button disabled">{this.state.button}</span>
                )}

                
              </Fragment>
            }
            {this.state.timer_time > 0 &&
              <span className="countdown">
                {this.state.showBtn ? (
                  <Countdown 
                    date={Date.now() + this.state.timer_time} 
                    renderer={renderer} 
                    onComplete={() => this.setState({ showBtn: false })}
                  />
                ) : (
                  <span><Trans i18nKey="Your offer has expired" /></span>
                )}
              </span>
            }
          </Col>
        </Row>
      </Col>
    );
  }
}

export default ProductUpsell;