import React, { Fragment } from "react";
import {
  Col,
  Row,
  Spinner
} from 'react-bootstrap';
import sendMessage from './components/SendMessage';
import Cart from './components/Cart';
import CartUpsellProduct from './components/CartUpsellProduct';
import CartCrossSellProduct from './components/CartCrossSellProduct';
import CartSingleProduct from './components/CartSingleProduct';
import EmptyCart from './components/EmptyCart';
import CartFooter from './components/CartFooter';
import CartHeader from './components/CartHeader';
import $ from 'jquery';
import axios from 'axios';
import i18next from "i18next";
import _ from 'lodash';

class HypeCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartIsOpen: false,
      backend_url: process.env.REACT_APP_BACKEND_URL,
      cart_popups: process.env.REACT_APP_CART_POPUP,
      cart_items: [],
      cartIsEmpty: true,
      cartIsLoaded: false,
      currency: "SEK",
      total_cart_value: 0,
      product_handle: null,
      show_product: false,
      show_cart: true,
      product_quantity: 0,
      cart_items_number: 0,
      variant: null,
      showSave: false,
      shop: null,
      loading: true,
      cart_is_done: false,
      loaded_triggers: 0,
      theme: 'cart-light',
      show_note: false,
      discounts: [],
      upsell_discounts: [],
      total_discount: 0,
      note: '',
      note_heading: '',
      free_gift_variant: null,
      offer_headline: false,
      triggers: {
        crossSells: [],
        progressBars: [],
        upsellProduct: []
      },
      custom_theme: {
        background_color: '',
        product_card_background_color: '',
        primary_text: '',
        secondary_text: '',
        tertiary_color: '',
        progress_bar: '',
        checkout_button_background: '',
        checkout_button_text: ''
      }
    };
  }

  getTriggers = async () => {
    await axios.post(this.state.backend_url+'/cart/info', { shop: this.state.shop })
    .then((response) => {
      let triggers = response.data.cart_triggers;
      //let triggers = response.data.triggers;
      let lang = response.data.lang;
      let product_image_contain;
      let group_offers = false;
      let offer_headline
      
      if(response.data.product_image_contain) {
        product_image_contain = response.data.product_image_contain
      } else {
        product_image_contain = false;
      }

      if(response.data.group) {
        group_offers = response.data.group;
      }

      if(response.data.offer_headline) {
        offer_headline = response.data.offer_headline
      }
      
      if(lang !== 'en') {
        i18next.changeLanguage(lang, (err, t) => {
          if (err) return console.log('something went wrong loading', err);
        });
      }
      this.setState({
        triggers: triggers, 
        theme: response.data.theme,
        show_note: response.data.show_note,
        note_heading: response.data.note_heading,
        show_terms: response.data.show_terms,
        terms_heading: response.data.terms_heading,
        terms_url: response.data.terms_url,
        custom_theme: response.data.custom_theme,
        product_image_contain: product_image_contain,
        group_offers: group_offers,
        offer_headline: offer_headline
      });

      return true;
    });
  }

  getPlan = () => {
    axios.post(this.state.backend_url+'/shop/info', { shop: this.state.shop })
    .then((response) => {
      this.setState({ plan: response.data.hypecart_plan});
    });
  }

  updateTriggerValues = async () => {

    let total_price = 0;
    let total_quantity = 0;
    if(this.state.cart_items) {
      
      for(const item of this.state.cart_items) {
        total_price = total_price + (item.price * item.quantity);
        if(item.product_type !== "Free Gift") {
          total_quantity = total_quantity + item.quantity;
        } else {
          if (item.quantity > 1) {
            sendMessage('updateQuantity', {'id': item.id, 'quantity': 1});
          }
        }
      }

      if(this.state.triggers) {
        for(const progressBar of this.state.triggers.progressBars) {
          if(progressBar.active) {
            progressBar.left_to_spend = Number(progressBar.threshold - (total_price / 100)).toFixed(2);
            progressBar.progress = Number(((total_price / 100) / progressBar.threshold) * 100).toFixed(1);
            progressBar.progress_quantity = Number(progressBar.threshold - total_quantity)

            let gift_id;

            if(this.state.free_gift_variant) {
              gift_id = parseInt(this.state.free_gift_variant)
            } else {
              gift_id = parseInt(progressBar.gift_id)
            }

            let productIndex = _.findIndex(this.state.cart_items, {product_id: parseInt(progressBar.gift_product_id)})

            switch(progressBar.type) {
              case 'cart_value':
                progressBar.progressbar = Number(((total_price / 100) / progressBar.threshold) * 100).toFixed(1);

                if(progressBar.gift) {
                  if(this.state.total_cart_value/100 < progressBar.threshold ) {
                    // No gift
                    if(_.find(this.state.cart_items, {product_id: parseInt(progressBar.gift_product_id)})) {
                      sendMessage('updateQuantity', {'id': this.state.cart_items[productIndex].id, 'quantity': 0});
                      //sendMessage('updateQuantity', {'id': gift_id, 'quantity': 0});
                    }
                  } else {
                    // Gift
                    if(!_.find(this.state.cart_items, {product_id: parseInt(progressBar.gift_product_id)})) {
                      sendMessage('addProduct', {'id': gift_id, 'quantity': 1});
                    }
                  }
                }

                break;
              case 'cart_quantity':
                progressBar.progressbar = Number(total_quantity/progressBar.threshold * 100)

                if(progressBar.gift) {
                  if(total_quantity >= progressBar.threshold ) {
                    if(!_.find(this.state.cart_items, {product_id: parseInt(progressBar.gift_product_id)})) {
                      sendMessage('addProduct', {'id': gift_id, 'quantity': 1});
                    }
                    
                  } else {
                    if(_.find(this.state.cart_items, {product_id: parseInt(progressBar.gift_product_id)})) {
                      sendMessage('updateQuantity', {'id': this.state.cart_items[productIndex].id, 'quantity': 0});
                      //sendMessage('updateQuantity', {'id': gift_id, 'quantity': 0});
                    }
                    
                  }
                }

                break;
              default:
                progressBar.progressbar = Number(((total_price / 100) / progressBar.threshold) * 100).toFixed(1);

                if(progressBar.gift) {
                  if(this.state.total_cart_value/100 < progressBar.threshold ) {
                    // No gift
                    if(_.find(this.state.cart_items, {id: progressBar.gift_id})) {
                      sendMessage('updateQuantity', {'id': progressBar.gift_id, 'quantity': 0});
                    }
                  } else {
                    // Gift
                    if(!_.find(this.state.cart_items, {id: progressBar.gift_id})) {
                      sendMessage('addProduct', {'id': progressBar.gift_id, 'quantity': 1});
                    }
                  }
                }
                break;
            }



          }
        }
        //console.log("send from react")

        let discounts = []
        let upsell_discounts = []

        for (const item of this.state.triggers.crossSells) {
          if(item.variant_id) {
            if(item.discount) {
              if(item.discount !== "null") {
                discounts.push({variant_id: item.variant_id, type: item.discount, value: item.discount_value})
              }
            }
          }
        }

        let cart_items = this.state.cart_items
        let total_discount = 0

        for (const item of cart_items) {
          let search = _.find(discounts, ['variant_id', String(item.variant_id)])
          if(search) {
            item.discount = true
            item.discount_type = search.type
            item.discount_value = search.value
            upsell_discounts.push(search)

            switch (search.type) {
              case 'percentage':
                let difference =  Number(item.price/100) * (search.value/100)
                total_discount = total_discount + difference
                break;
              case 'fixed_amount':
                let fixed_difference = Number(item.price/100) - search.value
                fixed_difference = Number(item.price/100) - fixed_difference
                total_discount = total_discount + fixed_difference
                break;
              default:
                total_discount = total_discount + Number(item.price/100);
                break;
            }
          }
        }

        this.setState({cart_items: cart_items, total_discount: total_discount})

        sendMessage('letsgo');
      }
      
    }
    this.setState({ loading: false });
  }


  openCart = () => {
    sendMessage('openCart', this.state.cart_popups);
    $('.cart-overlay').fadeIn(700);
    this.setState({cartIsOpen: true})
  }

  closeCart = () => {
    sendMessage('closeCart');
    $('.cart-overlay').hide();
    this.setState({cartIsOpen: false})
  }

  getCart = (cartItems) => {
    let cart_items;
    if(cartItems.items){
      cart_items = cartItems.items
    } else {
      cart_items = cartItems
    }

    this.setState({
      cart_items: cart_items, 
      og_cart_items: cart_items, 
      total_cart_value: 0, 
      cart_items_number: 0
    }, () => {
      if(this.state.cart_items.length > 0){
        let total_price = 0;
        let total_quantity = 0;
        let total_discount = [];
        for(const item of this.state.cart_items) {
          if(item.product_type !== "Free Gift") {
            total_price = total_price + (item.price * item.quantity);
            total_quantity = total_quantity + item.quantity;
          }
        }
        if(cartItems.cart_level_discount_applications) {
          for(const item of cartItems.cart_level_discount_applications) {
            total_discount.push({title: item.title, value: item.value})
            total_price = total_price - parseFloat(item.value * 100)
          }
        }
        this.setState({
          total_cart_value: total_price,
          cart_items_number: total_quantity,
          discounts: total_discount,
          cartIsEmpty: false,
        }, () => {
          this.updateTriggerValues()
        });
      } else {
        this.setState({cartIsEmpty: true})
      }
    });
  }

  getProduct = (productHandle, index) => {
    this.setState({
      product_handle: productHandle, 
      product_index: index
    }, () => {
      if(this.state.product_handle !== null){
        sendMessage('getProduct', this.state.product_handle);
      }
    });
  }
  
  getShop = async () => {
    let shop = window.location.search.substring(6);
    if(shop) {
      this.setState({ shop });
      return true;
    }
  }

  updateCart = () => {
    //sendMessage('updateCart');
  }

  closeProduct = () => {
    this.setState({
      product_handle: null,
      product_index: null,
      product_quantity: 0,
      current_product: null,
      show_product: false, 
      show_upsell_product: false,
      show_crossSell_product: false,
      show_cart: true
    })
  }

  handleAddProduct = (id, quantity) => {
    this.setState({
      upsell_product_id: id,
      upsell_product_quantity: quantity
    })
  }

  handleCrossSellAddProduct = (id, quantity) => {
    this.setState({
      crossSell_product_id: id,
      crossSell_product_quantity: quantity
    })
  }

  addProduct = (id, quantity) => {
    this.setState({ loading: true });
    sendMessage('addProduct', { 'id': id, 'quantity': quantity, 'cart_bubble': process.env.REACT_APP_CART_BUBBLE });
  }

  updateCartItem = () => {

    this.setState({ loading: true });
      if(
        parseInt(this.state.og_cart_items[this.state.product_index].variant_id) === parseInt(this.state.variant)
        ) {
        sendMessage('updateQuantity', {
          'id': this.state.cart_items[this.state.product_index].variant_id,
          'quantity': this.state.cart_items[this.state.product_index].quantity
        });
      }
      if(
        parseInt(this.state.og_cart_items[this.state.product_index].variant_id) !== parseInt(this.state.variant)
        ) {
          sendMessage('updateVariant', { 'oldID': this.state.og_cart_items[this.state.product_index].variant_id,'newID': this.state.variant, 'quantity': this.state.cart_items[this.state.product_index].quantity, 'cart_bubble': process.env.REACT_APP_CART_BUBBLE });
      }
    
  }

  createConversionClick = (conversion_id, product_id) => {
    axios.post(this.state.backend_url+'/conversions/create', {
      shop: this.state.shop,
      cart_token: this.state.cart_token,
      conversion_id: conversion_id,
      product_id: product_id
    })
    .then((response) => {
      //Do stuff
    });
  }

  addUpsellProductToCart = (index) => {
    this.setState({
      show_cart: false,
      show_product: false,
      show_crossSell_product: false,
      show_upsell_product: true,
      upsell_product_index: index
    });

    let conversion_id = this.state.triggers.upsellProduct[index].id;
    let product_id = this.state.triggers.upsellProduct[index].item.id;
    this.createConversionClick(conversion_id, product_id);
  }

  addCrossSellProductToCart = (index) => {
    this.setState({
      show_cart: false,
      show_product: false,
      show_upsell_product: false,
      show_crossSell_product: true,
      crossSell_product_index: index
    });

    let conversion_id = this.state.triggers.crossSells[index].id;
    let product_id = this.state.triggers.crossSells[index].item.id;
    this.createConversionClick(conversion_id, product_id);
  }

  receiveMessage = (event) => {
    const message = event.data.message;
    switch (message) {
      case 'openCart':
        this.openCart();
        break;
      case 'closeCart':
        this.closeCart();
        break;
      case 'getCart':
        this.getCart(event.data.value);
        break;
      case 'getToken':
        this.setState({ cart_token: event.data.value });
        break;
      case 'setNote':
        this.setState({ note: event.data.value });
        break;
      case 'updateCart':
        this.getCart(event.data.value);
        this.setState({
          product_handle: null,
          product_index: null,
          product_quantity: 0,
          current_product: null,
          show_product: false, 
          show_upsell_product: false,
          show_crossSell_product: false,
          show_cart: true
        })
        this.setState({ loading: false });
        break;
      case 'cartUpdated':
        this.getCart(event.data.value.items);
        this.setState({ 
          show_cart: true,
          show_product: false, 
          show_upsell_product: false, 
          show_crossSell_product: false
        });
        this.setState({ loading: false });
        break;
      case 'getCurrency':
        this.setState({currency: event.data.value})
        break;
      case 'getProduct':
        this.setState({
          current_product: event.data.value, 
          show_product: true,
          show_upsell_product: false,
          show_crossSell_product: false,
          show_cart: false, 
          product_quantity: this.state.cart_items[this.state.product_index].quantity, 
          variant: this.state.cart_items[this.state.product_index].variant_id
        });
        break;
      case 'getUpsellProduct':
        let triggers = this.state.triggers;
        let upsell_index = event.data.value.index;
        let upsell_product = event.data.value.product;

        triggers.upsellProduct[upsell_index].item = upsell_product;
        this.setState({
          triggers: triggers
        });
        break;
      case 'getCrossSellProduct':
        let crossSell_triggers = this.state.triggers;
        let index = event.data.value.index;
        let product = event.data.value.product;

        crossSell_triggers.crossSells[index].item = product;
        this.setState({
          triggers: crossSell_triggers
        });
        break;
      case 'getRecommendations':
        let recommendedTriggers = this.state.triggers;
        let recommendedIndex = event.data.value.index;
        let recommendedProduct = _.sample(event.data.value.products.products);

        recommendedTriggers.crossSells[recommendedIndex].item = recommendedProduct;
        recommendedTriggers.crossSells[recommendedIndex].product_handle = recommendedProduct.handle;
        this.setState({
          triggers: recommendedTriggers
        });
        break;
      case 'getParams':
        let params = event.data.value;
        if(!_.isEmpty(params)) {
          sessionStorage.setItem('attributes', JSON.stringify(params));
        }
        break;
         default:
        break;
    }
  }

  handleState = async (name, val) => {
    switch(name) {
      case 'triggers':
        this.setState({ triggers: val })
        return true;

      case 'cart_items':
        this.setState({ cart_items: val })
        return true;

      case 'variant':
        this.setState({ variant: val })
        return true;

      case 'showSave':
        this.setState({ showSave: val })
        return true;
      
      case 'free_gift_variant':
        this.setState({ free_gift_variant: val })
        return true;

      case 'showCrossSell':
        let crossSellTriggers = this.state.triggers;
        let crossSellIndex = val.index;

        crossSellTriggers.crossSells[crossSellIndex].active = val.show;
        this.setState({
          triggers: crossSellTriggers
        });

        //this.setState({ showCrossSell: val })
        return true;

      case 'loaded_triggers':
        this.setState({
          loaded_triggers: this.state.loaded_triggers+1
        }, () => {
          this.checkLoader();
        });
        
        return true;
        
      default:
        break;
    }
  }

  checkLoader = () => {
    let triggers = this.state.triggers;
    let loaded_triggers = this.state.loaded_triggers;
    let count = 0;
    
    for(const upsell of triggers.upsellProduct) {
      if(upsell.active) {
        count = count+1
      }
    }
    for(const crossSell of triggers.crossSells) {
      if(crossSell.active) {
        count = count+1
      }
    }
    for(const progressBar of triggers.progressBars) {
      if(progressBar.active) {
        count = count+1
      }
    }

    if(count === loaded_triggers) {
      this.setState({ cart_is_done: true });
    }
  }

  updateNote = (val) => {
    this.setState({ note: val.target.value })
  };

  componentDidMount() {
    this.getShop().then(async() => {
      sendMessage('getCart');
      this.getPlan();
      window.addEventListener("message", this.receiveMessage, false);
      await this.getTriggers();
      await this.updateTriggerValues();
      this.setState({cartIsLoaded: true})
      sendMessage('getEnv', {
        cart_bubble: process.env.REACT_APP_CART_BUBBLE, 
        popup: process.env.REACT_APP_CART_POPUP,
        atc: process.env.REACT_APP_ATC
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage, false);
  }

  render() {


    return (
      <Fragment>
        <Col className="cart-overlay" onClick={() => this.closeCart()}>
        </Col>
        <Col className="cart-container">
          <Col className={`cart ${this.state.theme}`}>
            <CartHeader
              closeCart={() => this.closeCart()}
              updateCart={() => this.updateCart()}
              closeProduct={() => this.closeProduct()}
              {...this.state}
            />
            <Row className="cart-items overflow-auto">
              {this.state.cartIsLoaded ? (
                <Fragment>
                  {!this.state.cartIsEmpty ? (
                    <Fragment>
                      {this.state.show_cart &&
                        <Cart
                          getProduct={(productHandle, index) => this.getProduct(productHandle, index)}
                          addUpsellProductToCart={(index) => this.addUpsellProductToCart(index)}
                          addCrossSellProductToCart={(index) => this.addCrossSellProductToCart(index)}
                          handleState={(name, val) => this.handleState(name, val)}
                          {...this.state}
                        />
                      }
                      {this.state.show_upsell_product &&
                        <CartUpsellProduct
                          handleAddProduct={(id, quantity) => this.handleAddProduct(id, quantity)}
                          {...this.state}
                        />
                      }
                      {this.state.show_product &&
                        <CartSingleProduct
                          handleState={(name, val) => this.handleState(name, val)}
                          {...this.state}
                        />
                      }
                      {this.state.show_crossSell_product &&
                        <CartCrossSellProduct
                          handleCrossSellAddProduct={(id, quantity) => this.handleCrossSellAddProduct(id, quantity)}
                          {...this.state}
                        />
                      }
                    </Fragment>
                  ) : (
                    <EmptyCart />
                  )}
                </Fragment>
              ):(
                <Col className="empty-cart">
                  <Spinner animation="border" role="status" />
                </Col>
              )}
            </Row>
            <CartFooter 
              {...this.state} 
              updateCartItem={() => this.updateCartItem()} 
              updateCart={() => this.updateCart()}
              updateNote={(val) => this.updateNote(val)}
              addProduct={(id, quantity) => this.addProduct(id, quantity)}
              closeProduct={() => this.closeProduct()}
            />
          </Col>
        </Col>
        {this.state.theme === 'cart-custom' &&
          <style dangerouslySetInnerHTML={{__html: `
              @media(max-width: 550px) {
                .cart.cart-custom .cart-product-upsell .price button, 
                .cart.cart-custom .cart-product-cross-sell .price button {
                  background: ${this.state.custom_theme.background_color};
                  color: var(--custom-6);
                }
              }
              
              .cart.cart-custom .empty-cart {
                color: var(--custom-4);
              }
              
              .cart.cart-custom .cart-footer .checkout button {
                background-color: ${this.state.custom_theme.checkout_button_background};
                color: ${this.state.custom_theme.checkout_button_text};
                border-color: ${this.state.custom_theme.checkout_button_background};
              }
              
              .cart.cart-custom .cart-footer .back-to-cart button {
                color: ${this.state.custom_theme.checkout_button_background};
                border-color: ${this.state.custom_theme.checkout_button_background};
              }
              
              .cart.cart-custom .cart-footer .save-cart button {
                background-color: ${this.state.custom_theme.checkout_button_background};
                color: ${this.state.custom_theme.checkout_button_text};
                border-color: ${this.state.custom_theme.checkout_button_background};
              }
              
              .cart.cart-custom .cart-footer {
                color: ${this.state.custom_theme.primary_text};
                background-color: ${this.state.custom_theme.background_color};
                border-top: 1px solid ${this.state.custom_theme.secondary_text};
              }
              
              .cart.cart-custom .cart-product-upsell button,
              .cart.cart-custom .cart-product button {
                background-color: transparent;
                color: ${this.state.custom_theme.background_color};
                border-color: ${this.state.custom_theme.background_color};
              }
              
              .cart.cart-custom .cart-product-upsell button:hover,
              .cart.cart-custom .cart-product button:hover {
                background-color: ${this.state.custom_theme.background_color};
                color: var(--custom-6);
                border-color: ${this.state.custom_theme.background_color};
              }
              
              .cart.cart-custom .cart-product-upsell button,
              .cart.cart-custom .cart-product button {
                color: ${this.state.custom_theme.background_color};
                border-color: ${this.state.custom_theme.background_color};
              }
              .cart.cart-custom .cart-product-upsell button:hover,
              .cart.cart-custom .cart-product button:hover {
                background-color: ${this.state.custom_theme.background_color};
                color: var(--custom-1);
                border-color: ${this.state.custom_theme.background_color};
              }

              .cart.cart-custom h2.offers {
                color: ${this.state.custom_theme.primary_text};
              }

              .cart.cart-custom .cart-product .price span svg {
                fill: ${this.state.custom_theme.tertiary_color};
              }
              .cart.cart-custom .cart-product .price span {
                color: ${this.state.custom_theme.primary_text};
              }
              .cart.cart-custom {
                background-color: ${this.state.custom_theme.background_color};
              }
              .cart.cart-custom .bg-success {
                background-color: ${this.state.custom_theme.progress_bar} !important;
              }
              .cart.cart-custom h1,
              .cart.cart-custom .cart-edit .quantity,
              .cart.cart-custom .cart-edit select  {
                color: ${this.state.custom_theme.primary_text};
                background: transparent;
                border-color: ${this.state.custom_theme.tertiary_color};
              }
              .cart.cart-custom .cart-footer textarea {
                background: ${this.state.custom_theme.background_color};
                color: ${this.state.custom_theme.secondary_text};
              }
              .cart.cart-custom .cart-header h4,
              .cart.cart-custom .cart-product h4,
              .cart.cart-custom .cart-product-progress h4,
              .cart.cart-custom .cart-product-upsell h4,
              .cart.cart-custom .cart-product-cross-sell h4,
              .cart.cart-custom .cart-product-edit {
                color: ${this.state.custom_theme.secondary_text};
              }
              .cart.cart-custom .cart-header {
                background-color: ${this.state.custom_theme.background_color};
                border-bottom: 1px solid ${this.state.custom_theme.secondary_text};
              }
              .cart.cart-custom .cart-header .close {
                color: ${this.state.custom_theme.secondary_text};
              }
              .cart.cart-custom .cart-product,
              .cart.cart-custom .cart-product-progress,
              .cart.cart-custom .cart-product-upsell,
              .cart.cart-custom .cart-product-cross-sell,
              .cart.cart-custom .cart-product-loading,
              .cart.cart-custom .cart-product-edit,
              .cart.cart-custom .cart-edit {
                background-color: ${this.state.custom_theme.product_card_background_color};
                border: 0.5px solid ${this.state.custom_theme.tertiary_color};
              }
              .cart.cart-custom .cart-edit .btn-outline-secondary {
                color: ${this.state.custom_theme.primary_text};
                border-color: ${this.state.custom_theme.tertiary_color};
                background-color: transparent;
              }

              .cart.cart-custom .cart-product h3, 
              .cart.cart-custom .cart-product-progress h3, 
              .cart.cart-custom .cart-product-upsell h3, 
              .cart.cart-custom .cart-product-cross-sell h3  {
                color: ${this.state.custom_theme.primary_text};
              }
          `}}></style>
        }
      </Fragment>
    );
  }
};

export default HypeCart;