import React, { Fragment } from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import _ from 'lodash';
import axios from 'axios';
import sendMessage from './SendMessage';
import { FaChevronRight } from "react-icons/fa";
import formatCurrency from '../Helpers';

class CrossSell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subtitle: "",
      showCrossSell: false,
      isInCart: false,
      loading_cross_sell: true,
    };
  }

  getCrossSellProduct = (productHandle, index) => {
    sendMessage('getCrossSellProduct', { product_handle: productHandle, index: index});
  }

  isCrossSellProductInCart = async () => {
    let isInCart = _.find(this.props.cart_items, ['handle', this.props.triggers.crossSells[this.props.index].product_handle]);
    if(isInCart) {
      this.setState({isInCart: false})
    } else {
      this.setState({isInCart: true})
    } return true
  }

  validateCrossSell = async () => {
    const triggers = this.props.crossSellTriggers;
    const total_cart_value = parseFloat(Number(this.props.total_cart_value/100).toFixed(2));
    let boolean_value = false;
    let boolean_and_value = [];
    
    let operators = {
      '<': function(a, b){ return a<=b},
      '>': function(a, b){ return a>=b},
      '=': function(a, b){ return a===b}
    }

    for(const trigger of triggers) {
      let operator = trigger.operator;
      if(trigger.andor === 'or') {

        if(trigger.if === 'product') {
          if(operator === 'includes') {
            let product_bool_arr = []
            for(const trigger_value of trigger.value) {
              const productIsInCart = _.find(this.props.cart_items, ['product_id', parseInt(trigger_value)]);
              if(productIsInCart && trigger_value) {
                boolean_and_value.push(true);
              }
            }
          }

          if(operator === 'not_includes') {
            let product_bool_arr = []
            for(const trigger_value of trigger.value) {
              const productIsInCart = _.find(this.props.cart_items, ['product_id', parseInt(trigger_value)]);
              if(productIsInCart === undefined && trigger_value) {
                product_bool_arr.push(true);
              }
            }
            if(product_bool_arr.length === trigger.value.length) {
              boolean_and_value.push(false);
            }
          }
        }

        if(trigger.if === 'variant') {
          if(operator === 'includes') {
            let variant_bool_arr = []
            for(const trigger_value of trigger.value) {
              const variantIsInCart = _.find(this.props.cart_items, ['variant_id', parseInt(trigger_value)]);
              if(variantIsInCart && trigger_value) {
                variant_bool_arr.push(true);
              }
            }
            if(variant_bool_arr.length === trigger.value.length) {
              boolean_and_value.push(true);
            }
          }

          if(operator === 'not_includes') {
            let variant_bool_arr = []
            for(const trigger_value of trigger.value) {
              const variantIsInCart = _.find(this.props.cart_items, ['variant_id', parseInt(trigger_value)]);
              if(variantIsInCart === undefined && trigger_value) {
                variant_bool_arr.push(true);
              }
            }
            if(variant_bool_arr.length === trigger.value.length) {
              boolean_and_value.push(false);
            }
          }
        }

        if(trigger.if === 'product_tag') {
          
          await axios.post(this.props.backend_url+'/cart/tags', {
            shop: this.props.shop,
            cart: this.props.cart_items
          }).then((response) => {
            if(response.status === 200) {
              let tags_in_cart = response.data.tags;

              if(operator === 'includes') {
                if(tags_in_cart.includes(trigger.value)) {
                  boolean_value = true;
                }
              }

              if(operator === 'not_includes') {
                if(!tags_in_cart.includes(trigger.value)) {
                  boolean_value = false;
                }
              }
            }
          }).catch((err) => {
            console.log(err);
          });

        }

        if(trigger.if === 'product_type') {
          if(operator === 'includes') {
            const productTypeIsInCart = _.find(this.props.cart_items, ['product_type', trigger.value]);
            if(productTypeIsInCart) {
              boolean_value = true;
            }
          }

          if(operator === 'not_includes') {
            const productTypeIsInCart = _.find(this.props.cart_items, ['product_type', trigger.value]);
            if(productTypeIsInCart === undefined) {
              boolean_value = false;
            }
          }
        }

        if(trigger.if === 'product_vendor') {
          if(operator === 'includes') {
            const productVendorIsInCart = _.find(this.props.cart_items, ['vendor', trigger.value]);
            if(productVendorIsInCart) {
              boolean_value = true;
            }
          }

          if(operator === 'not_includes') {
            const productVendorIsInCart = _.find(this.props.cart_items, ['vendor', trigger.value]);
            if(productVendorIsInCart === undefined) {
              boolean_value = false;
            }
          }
        }

        if(trigger.if === 'subtotal') {
          let value = parseFloat(Number(trigger.value).toFixed(2));
          if(operators[operator](total_cart_value, value)) {
            boolean_value = true;
          }
        }
      }

      if(trigger.andor === 'and') {
        
        if(trigger.if === 'product') {
          if(operator === 'includes') {
            let product_bool_arr = []
            for(const trigger_value of trigger.value) {
              const productIsInCart = _.find(this.props.cart_items, ['product_id', parseInt(trigger_value)]);
              if(productIsInCart) {
                boolean_and_value.push(true);
              }
            }
          }

          if(operator === 'not_includes') {
            let product_bool_arr = []
            for(const trigger_value of trigger.value) {
              const productIsInCart = _.find(this.props.cart_items, ['product_id', parseInt(trigger_value)]);
              if(productIsInCart === undefined && trigger_value) {
                product_bool_arr.push(true);
              }
            }

            if(product_bool_arr.length === trigger.value.length) {
              boolean_and_value.push(true);
            }
          }
        }

        if(trigger.if === 'variant') {
          if(operator === 'includes') {
            let variant_bool_arr = []
            for(const trigger_value of trigger.value) {
              const variantIsInCart = _.find(this.props.cart_items, ['variant_id', parseInt(trigger_value)]);
              if(variantIsInCart && trigger_value) {
                variant_bool_arr.push(true);
              }
            }
            if(variant_bool_arr.length === trigger.value.length) {
              boolean_and_value.push(true);
            }
          }

          if(operator === 'not_includes') {
            let variant_bool_arr = []
            for(const trigger_value of trigger.value) {
              const variantIsInCart = _.find(this.props.cart_items, ['variant_id', parseInt(trigger_value)]);
              if(variantIsInCart === undefined && trigger_value) {
                variant_bool_arr.push(true);
              }
            }
            if(variant_bool_arr.length === trigger.value.length) {
              boolean_and_value.push(false);
            }
          }
        }

        if(trigger.if === 'product_tag') {
          
          await axios.post(this.props.backend_url+'/cart/tags', {
            shop: this.props.shop,
            cart: this.props.cart_items
          }).then((response) => {
            if(response.status === 200) {
              let tags_in_cart = response.data.tags;

              if(operator === 'includes') {
                if(tags_in_cart.includes(trigger.value)) {
                  boolean_and_value.push(true);
                }
              }

              if(operator === 'not_includes') {
                if(!tags_in_cart.includes(trigger.value)) {
                  boolean_and_value.push(false);
                }
              }
            }
          }).catch((err) => {
            console.log(err);
          });

        }

        if(trigger.if === 'product_type') {
          if(operator === 'includes') {
            const productTypeIsInCart = _.find(this.props.cart_items, ['product_type', trigger.value]);
            if(productTypeIsInCart) {
              boolean_and_value.push(true);
            }
          }

          if(operator === 'not_includes') {
            const productTypeIsInCart = _.find(this.props.cart_items, ['product_type', trigger.value]);
            if(productTypeIsInCart === undefined) {
              boolean_and_value.push(false);
            }
          }
        }

        if(trigger.if === 'product_vendor') {
          if(operator === 'includes') {
            const productVendorIsInCart = _.find(this.props.cart_items, ['vendor', trigger.value]);
            if(productVendorIsInCart) {
              boolean_and_value.push(true);
            }
          }

          if(operator === 'not_includes') {
            const productVendorIsInCart = _.find(this.props.cart_items, ['vendor', trigger.value]);
            if(productVendorIsInCart === undefined) {
              boolean_and_value.push(false);
            }
          }
        }

        if(trigger.if === 'subtotal') {
          let value = parseFloat(Number(trigger.value).toFixed(2));
          if(operators[operator](total_cart_value, value)) {
            boolean_and_value.push(true);
          }
        }

      }

    }

    if(triggers.length === boolean_and_value.length) {
      boolean_value = true;
    }
    if(!this.state.isInCart) {
      boolean_value = false;
    }

    this.props.handleState('showCrossSell', {index: this.props.index, show: boolean_value})

  }


  receiveMessage = (event) => {
    const message = event.data.message;
    switch (message) {
      case 'getCrossSellProduct':
        
          let crossSell_triggers = this.props.triggers;
          let index = event.data.value.index;
          let product = event.data.value.product;

          crossSell_triggers.crossSells[index].item = product;

          this.props.handleState('triggers', crossSell_triggers).then(() => {
            this.isCrossSellProductInCart().then(() => {
              this.validateCrossSell().then(() => {
                this.setState({ loading_cross_sell: false });
                this.props.handleState('loaded_triggers', 1);
              });
            });
          });

        break;
      default:
        break;
    }
  }

  componentDidMount() {
    switch(this.props.productHandle) {
      // These two needs to go to the same function in main.dev.js
      case "shopify_product_recommendation":
        var product = _.sample(this.props.cart_items);
        sendMessage('getRecommendations', { product_id: product.product_id, index: this.props.index});
        break;
      default:
        this.getCrossSellProduct(this.props.productHandle, this.props.index);
        break;
    }
    window.addEventListener("message", this.receiveMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage, false);
  }

  render() {
    const subtitle = () => {
      let subtitle = this.props.subtitle;
      let return_value;
      if(subtitle) {
        if(this.props.triggers.crossSells[this.props.index].item) {
          return_value = subtitle.replace('{price}', formatCurrency(Number(this.props.triggers.crossSells[this.props.index].item.price / 100), this.props.currency));

          return_value = return_value.replace('{title}', this.props.triggers.crossSells[this.props.index].item.title)

        } else {
          return_value = "";
        }
      } else {
        return_value = "";
      }
      return return_value;
    }

    const title = () => {
      let title = this.props.title;
      let return_value;
      if(title) {
        if(this.props.triggers.crossSells[this.props.index].item) {
          return_value = title.replace('{price}', formatCurrency(Number(this.props.triggers.crossSells[this.props.index].item.price / 100), this.props.currency));

          return_value = return_value.replace('{title}', this.props.triggers.crossSells[this.props.index].item.title)

        } else {
          return_value = "";
        }
      } else {
        return_value = "";
      }
      return return_value;
    }

    const image = () => {
      let return_value;
      if(this.props.triggers.crossSells[this.props.index].item) {
        if(this.props.triggers.crossSells[this.props.index].item.featured_image) {
          return_value = this.props.triggers.crossSells[this.props.index].item.featured_image;
        } else {
          return_value = "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-product-2_large.png";
        }
      } else {
        return_value = "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-product-2_large.png";
      }
      return return_value;
    }

    let background_type;
    if(this.props.product_image_contain) {
      background_type = 'contain';
    } else {
      background_type = 'cover';
    }

    return (
      <Fragment>
        {this.props.show &&
          <Fragment>
            <Row className="cart-product mobile-btn" onClick={() => this.props.addCrossSellProductToCart(this.props.index)}>
              <Col xs={3} className="cart-img-outer">
                <div 
                  className="cart-img" 
                  style={{  
                    backgroundImage: "url(" + image() + ")",
                    backgroundPosition: '50%',
                    backgroundSize: background_type,
                    backgroundRepeat: 'no-repeat'
                  }}
                ></div>
              </Col>
              
              <Col xs={8} className="meta">
                <span>
                  <h3>{title()}</h3>
                  <h4>{subtitle()}</h4>
                </span>
              </Col>
              <Col xs={1} className="price">
                <span>
                  <FaChevronRight />
                </span>
              </Col>
            </Row>
          </Fragment>
        }
      </Fragment>
    );
  }
}

export default CrossSell;


