import React, { Fragment } from "react";
import {
  Col
} from 'react-bootstrap';
import axios from "axios";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaPinterestSquare,
  FaLinkedin,
  FaSnapchatSquare,
  FaInstagram,
  FaTiktok
} from "react-icons/fa";
import sendMessage from "../SendMessage";

class SocialMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backend_url: process.env.REACT_APP_BACKEND_URL,
      shop: null,
      header: '',
      links: []
    };
  }

  getShop = async () => {
    function getQueryStringParams(query) {
      return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
    };
    let search = getQueryStringParams(window.location.search);
    if(search.shop) {
      this.setState({ shop: search.shop });
      return true;
    }
  }

  getSocialMedia = () => {
     axios.post(this.state.backend_url+'/widgets/get-social-media-links', {shop: this.state.shop})
      .then((response) => {
        this.setState({
          header: response.data.header,
          links: response.data.links
        }, () => {
          //let windowHeight = window.document.documentElement.scrollHeight;
          sendMessage('widgetDone');
        });
     });
  };

  componentDidMount() {
    this.getShop().then(async() => {
      this.getSocialMedia();
    });
  }

  render() {

    return (
      <Col className="box-border" data-iframe-height="iFrameResizer">
        <h4>{this.state.header}</h4>
        {this.state.links.map((item, index) => (
            <Fragment key={index}>
              {item.title === "Facebook" &&
                item.link !== "" && (
                  <a className="social-icon facebook" target="_blank" href={item.link} rel="noreferrer">
                    <FaFacebookSquare />
                  </a>
                )}
              {item.title === "Twitter" &&
                item.link !== "" && (
                  <a className="social-icon twitter" target="_blank" href={item.link} rel="noreferrer">
                    <FaTwitterSquare />
                  </a>
                )}
              {item.title === "Instagram" &&
                item.link !== "" && (
                  <a className="social-icon instagram" target="_blank" href={item.link} rel="noreferrer">
                    <FaInstagram />
                  </a>
                )}
              {item.title === "Pinterest" &&
                item.link !== "" && (
                  <a className="social-icon pinterest" target="_blank" href={item.link} rel="noreferrer">
                    <FaPinterestSquare />
                  </a>
                )}
              {item.title === "LinkedIn" &&
                item.link !== "" && (
                  <a className="social-icon linkedin" target="_blank" href={item.link} rel="noreferrer">
                    <FaLinkedin />
                  </a>
                )}
              {item.title === "Snapchat" &&
                item.link !== "" && (
                  <a className="social-icon snapchat" target="_blank" href={item.link} rel="noreferrer">
                    <FaSnapchatSquare />
                  </a>
                )}
              {item.title === "TikTok" &&
                item.link !== "" && (
                  <a className="social-icon tiktok" target="_blank" href={item.link} rel="noreferrer">
                    <FaTiktok />
                  </a>
                )}
            </Fragment>
          )
        )}
      </Col>
    );
  }
}

export default SocialMedia;